import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { FiCheck } from 'react-icons/fi';
import { DateRange } from '@/features/dashboard/reports/models';
import { VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { DataPickerContext } from '@/features/dashboard/shared/date-picker/date-picker';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  background-color: white;
  border-radius: 15px;
  width: 200px;
  padding: 1rem 3rem;
  font-family: 'JetBrains Mono', serif;
`;

const Item = styled.button`
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: ${(p: { isActive: boolean }) => (p.isActive ? `blue` : `#60686e`)};
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
  :active {
    opacity: 1;
  }
  svg {
    margin-right: 1rem;
  }
`;

export const Option: FC<{ isActive: boolean; onClick: () => void }> = ({
  children,
  onClick,
  isActive,
}) => (
  <Item onClick={onClick} isActive={isActive}>
    {isActive && <FiCheck />}
    {children}
  </Item>
);

export const RangeOptions: FC<{
  setShowPicker: () => void;
  close: () => void;
}> = ({ setShowPicker, close }) => {
  const { dateRange, setDateRange } = useContext(DataPickerContext);
  const [localRange, setLocalRange] = useState(dateRange);
  return (
    <Container>
      <Option
        isActive={localRange === DateRange.TODAY}
        onClick={() => setLocalRange(DateRange.TODAY)}
      >
        Today
      </Option>
      <Option
        isActive={localRange === DateRange.YESTERDAY}
        onClick={() => setLocalRange(DateRange.YESTERDAY)}
      >
        Yesterday
      </Option>
      <Option
        isActive={localRange === DateRange.LAST_7_DAYS}
        onClick={() => setLocalRange(DateRange.LAST_7_DAYS)}
      >
        Last 7 days
      </Option>
      <Option
        isActive={localRange === DateRange.LAST_30_DAYS}
        onClick={() => setLocalRange(DateRange.LAST_30_DAYS)}
      >
        Last 30 Days
      </Option>
      <Option
        isActive={localRange === DateRange.LAST_YEAR}
        onClick={() => setLocalRange(DateRange.LAST_YEAR)}
      >
        Last Year
      </Option>
      <Option
        isActive={localRange === DateRange.ALL_TIME}
        onClick={() => setLocalRange(DateRange.ALL_TIME)}
      >
        All Time
      </Option>
      <Option
        isActive={localRange === DateRange.CUSTOM}
        onClick={() => {
          setLocalRange(DateRange.CUSTOM);
          setShowPicker();
        }}
      >
        Custom
      </Option>
      <VSpace />
      <BigButton
        onClick={() => {
          setDateRange(localRange);
          close();
        }}
        background="blue"
        noTransform
        size="medium"
        fillWidth
      >
        Apply
      </BigButton>
    </Container>
  );
};
