import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  WelcomeModalContext,
  WelcomeModalStep,
} from '@/features/welcome-quiz/shared/context';
import { StoreStatsKind } from '@/webapi/use-store-stats';
import { VisuallyLogo } from '@/features/strapi/components/logo';

export interface WelcomeModalIntroProps {
  rootRef: React.MutableRefObject<HTMLElement>;
}

export function WelcomeModalIntro({ rootRef }: WelcomeModalIntroProps) {
  const { setCurrentStep, isGoalReached } = useContext(WelcomeModalContext);
  const [wrapperRect, setWrapperRect] = useState({
    top: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 0,
  } as DOMRect);

  const [borderRadius, setBorderRadius] = useState(`1.5rem`);

  useEffect(() => {
    if (rootRef?.current) {
      setTimeout(() => {
        const wrapper = rootRef.current.querySelector(
          `:nth-child(2)`,
        ) as HTMLElement;
        if (wrapper) {
          setBorderRadius(getComputedStyle(wrapper).borderRadius);
          setWrapperRect(wrapper.getBoundingClientRect());
        }
      }, 500);

      setTimeout(() => {
        onNextStep();
      }, 4000);
    }
  }, [rootRef]);

  const onNextStep = () => {
    if (!isGoalReached(StoreStatsKind.IS_WELCOME_EMAIL_INVITE_DONE)) {
      setCurrentStep(WelcomeModalStep.EMAIL_SETUP);
      return;
    }

    if (!isGoalReached(StoreStatsKind.IS_WELCOME_APP_EMBED_DONE)) {
      setCurrentStep(WelcomeModalStep.ENABLE_APP_EMBED);
    }

    if (!isGoalReached(StoreStatsKind.IS_WELCOME_DEMO_DONE)) {
      setCurrentStep(WelcomeModalStep.BOOK_DEMO);
    }
  };

  return (
    <Wrapper
      style={{
        top: wrapperRect.top,
        left: wrapperRect.left,
        bottom: wrapperRect.bottom,
        right: wrapperRect.right,
        height: wrapperRect.height,
        width: wrapperRect.width,
        borderRadius,
      }}
    >
      <VisuallyLogo wide />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  transition: opacity ease-out 1s;

  .gatsby-image-wrapper {
    transform: scale(1.3);
  }
`;
