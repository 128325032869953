import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import { FormInput } from '@/components/floating-label-text-input';
import { RolesSelect } from '@/features/teammates/RoleSelect';
import { BigButton } from '@/components/big-button';
import { ErrMsg } from '@/features/teammates/AddUserPopOver';
import { useCreateUserApi } from '@/features/teammates/useCreateUserApi';

interface AddUserModalProps extends SharedElementOverlayProps {
  hide: () => void;
  refreshUserList: () => void;
}

export function AddUserModal(props: AddUserModalProps) {
  const { hide, refreshUserList, ...rest } = props;

  const {
    loading,
    ctx,
    onCreateUser,
    errors,
    error,
    setIsSelectOpen,
    register,
    setRole,
  } = useCreateUserApi(hide, refreshUserList);

  return (
    <SharedElementOverlay {...rest} heightMultiplier={2.1}>
      <Content onSubmit={onCreateUser}>
        <p>Send An Invite</p>
        <CloseBtn
          onClick={() => {
            hide();
          }}
        >
          <AiOutlineClose size={15} />
        </CloseBtn>
        <FormInput
          errors={errors}
          disabled={loading}
          name="email"
          label="Email"
          type="email"
          register={register}
          required
        />
        <FormInput
          errors={errors}
          disabled={loading}
          name="firstName"
          label="First Name"
          register={register}
          required
        />
        <FormInput
          errors={errors}
          disabled={loading}
          name="lastName"
          label="Last Name"
          register={register}
          required
        />
        <RolesSelect
          currentRole={ctx?.account?.store?.role}
          setIsSelectOpen={setIsSelectOpen}
          onChange={(x) => {
            setRole(x?.value);
          }}
        />
        <BigButton
          type="submit"
          border="2px solid black"
          noTransform
          size="large"
          fillWidth
        >
          Send
        </BigButton>
        {error && <ErrMsg>Something went wrong..</ErrMsg>}
      </Content>
    </SharedElementOverlay>
  );
}

const CloseBtn = styled.button`
  position: absolute;
  right: 30px;
  background: none;
  border: none;
  top: -42px;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;

const Content = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 2rem;

  p:first-child {
    width: 100%;
    user-select: none;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
  }

  input[type='submit'],
  button {
    margin-top: 60px;
  }
`;
