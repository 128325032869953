/* eslint-disable no-nested-ternary */
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { currencySymbol } from '@/utils/currencies';
import { MetricKind } from '@/webapi/models';
import { ChartDot } from '@/features/details/charts/chart';
import { Card } from '@/components/card';
import { isBrowser } from '@/utils/browser';
import { ReportingDataContext } from '@/features/dashboard/reports/context';
import { StoreSummary } from '@/features/dashboard/reports/models';
import { MetricSelect } from '@/features/dashboard/reports/common';
import { VSpace } from '@/components/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Inter, serif;
`;

function transform(storeSummary: StoreSummary) {
  return storeSummary?.metrics?.map((m) => {
    const date = new Date(m.day)
      .toLocaleDateString()
      ?.split(`/`)
      ?.reverse()
      ?.join(`-`);
    const reduce = m.metrics.reduce((p, c) => {
      p[c.name] = c.value || 0;
      return p;
    }, {});
    return { date, ...reduce };
  });
}

function chartWidth() {
  return (
    isBrowser() &&
    Math.max(window.innerWidth * (window.innerWidth <= 1300 ? 0.77 : 0.5), 1000)
  );
}

export const StoreRevenueChart: FC = () => {
  const { storeSummary, nodata } = useContext(ReportingDataContext);
  const width = chartWidth();
  const data = transform(storeSummary);
  const [key, setKey] = useState(MetricKind.REVENUE);

  if (nodata) {
    return null;
  }

  return (
    <DetailsCard>
      <Container>
        <MetricSelect onChange={(v) => setKey(v.value)} dataKey={key} />
        <VSpace value={6} />
        <LineChart width={width} height={300}>
          <Tooltip
            formatter={formatter()}
            labelStyle={{ color: `white`, fontSize: `1.3rem` }}
            itemStyle={{
              color: `white`,
              fontSize: `1.2rem`,
              fontFamily: `Inter`,
            }}
            contentStyle={{
              background: `#4D4D4D`,
              borderRadius: `1rem`,
              border: `none`,
            }}
            wrapperStyle={{
              boxShadow: `0 5px 7px 0 rgba(26,47,116,0.22)`,
            }}
          />
          <XAxis
            dataKey="date"
            type="category"
            allowDuplicatedCategory={false}
          />
          <YAxis dataKey={key} type="number" tickFormatter={formatter()} />
          <CartesianGrid stroke="#e7ebed" />
          <Line
            isAnimationActive={false}
            dataKey={key}
            key={key}
            name={key}
            data={data}
            legendType="circle"
            stroke="#5f2af5"
            strokeWidth={2}
            dot={<ChartDot />}
          />
        </LineChart>
      </Container>
    </DetailsCard>
  );
};

function formatter() {
  const symbol = currencySymbol();
  return (tick) => `${symbol}${tick?.toLocaleString()}`;
}

const DetailsCard = styled(Card)`
  && {
    cursor: default;
    padding: 3.5rem;
    border-radius: 4rem;
    justify-content: center;
    align-items: center;
  }
`;
