import React, { useEffect, useState } from 'react';
import { BooleanParam, DateParam, useQueryParam } from 'use-query-params';
import {
  DailyMetrics,
  DateRange,
  StoreSummary,
} from '@/features/dashboard/reports_v2/models';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { DateRangeParam } from '@/features/dashboard/shared/date-picker/date-picker';
import { getQueryDate } from '@/features/dashboard/reports_v2/common';

export interface ReportingDataContext {
  storeSummary: StoreSummary;
  loading: boolean;
  net: boolean;
  dateRange: DateRange;
  startDate: Date;
  endDate: Date;
  dailySummary: DailyMetrics;
}

export const ReportingDataContext = React.createContext(
  {} as ReportingDataContext,
);

export function newReportDataCtx() {
  const [dateRange] = useQueryParam(`rn`, DateRangeParam);
  const [startDate] = useQueryParam(`s`, DateParam);
  const [endDate] = useQueryParam(`e`, DateParam);
  const [net] = useQueryParam(`n`, BooleanParam);
  const [storeSummary, setStoreSummary] = useState<StoreSummary | null>(null);
  const [dailySummary, setDailySummary] = useState<DailyMetrics | null>(null);
  const { getStoreSummary, loading, getStoreDailySummary } = useReportsApi();

  useEffect(() => {
    (async () => {
      const startDate = getQueryDate(`s`);
      const endDate = getQueryDate(`e`);
      const resp = await getStoreSummary(
        dateRange as DateRange,
        startDate,
        endDate,
      );
      setStoreSummary(parseResp(resp, net));
    })();
  }, [dateRange, startDate, endDate, net]);

  useEffect(() => {
    (async () => {
      const startDate = getQueryDate(`s`);
      const endDate = getQueryDate(`e`);
      const resp = await getStoreDailySummary(
        dateRange as DateRange,
        startDate,
        endDate,
      );
      if (resp?.metrics?.length > 0) {
        setDailySummary(resp);
      }
    })();
  }, [dateRange, startDate, endDate]);
  return {
    storeSummary,
    loading,
    net,
    dateRange,
    startDate,
    endDate,
    dailySummary,
  };
}

const parseResp = (r: StoreSummary, net: boolean) => {
  const totalOrders = (r.TotalOrders || 0) * 1;
  const totalNetRevenue = (r.TotalNetRevenue || 0) * 1;
  const totalRevenue = (r.TotalRevenue || 0) * 1;
  const totalItemsSold = (r.TotalItemsSold || 0) * 1;
  const totalSessions = (r.TotalSessions || 0) * 1;
  const directOrders = (r.DirectOrders || 0) * 1;
  const directProducts = (r.DirectProducts || 0) * 1;
  const directRev = (r.DirectRev || 0) * 1;
  const directNetRevenue = (r.DirectNetRevenue || 0) * 1;
  const viaExpTotalOrders = (r.ViaExpTotalOrders || 0) * 1;
  const viaExpTotalNetRev = (r.ViaExpTotalNetRev || 0) * 1;
  const viaExpTotalRev = (r.ViaExpTotalRev || 0) * 1;
  const sessionsWithExperience = (r.SessionsWithExperience || 0) * 1;
  const sessionsWithUpsells = (r.SessionsWithUpsells || 0) * 1;
  const assistedRev = (r.AssistedRev || 0) * 1;
  const assistedNetRev = (r.AssistedNetRev || 0) * 1;
  const assistedOrders = (r.AssistedOrders || 0) * 1;
  return {
    TotalOrders: totalOrders,
    TotalRevenue: net ? totalNetRevenue : totalRevenue,
    TotalItemsSold: totalItemsSold,
    TotalSessions: totalSessions,
    DirectOrders: directOrders,
    DirectProducts: directProducts,
    DirectRev: net ? directNetRevenue : directRev,
    ViaExpTotalOrders: viaExpTotalOrders,
    ViaExpTotalRev: net ? viaExpTotalNetRev : viaExpTotalRev,
    SessionsWithExperience: sessionsWithExperience,
    SessionsWithUpsells: sessionsWithUpsells,
    AssistedRev: net ? assistedNetRev : assistedRev,
    AssistedOrders: assistedOrders,
    AssistedNetRev: assistedNetRev,
    ViaExpTotalNetRev: viaExpTotalNetRev,
    TotalNetRevenue: totalNetRevenue,
    DirectNetRevenue: directNetRevenue,
  } as StoreSummary;
};
