import * as React from 'react';
import styled from 'styled-components';
import { StoreSummarySection } from '@/features/dashboard/reports/top_section';
import {
  newReportDataCtx,
  ReportingDataContext,
} from '@/features/dashboard/reports/context';
import { StoreRevenueChart } from '@/features/dashboard/reports/revenue_chart';
import { VSpace } from '@/components/spacing';
import { ExperiencesRevenue } from '@/features/dashboard/reports/experiences_revenue';
import {
  DataPickerContext,
  newDatePickerCtx,
} from '@/features/dashboard/shared/date-picker/date-picker';

export function Reports() {
  const ctx = newDatePickerCtx();
  const rdx = newReportDataCtx();

  if (!rdx.initialized) {
    return null;
  }

  return (
    <DataPickerContext.Provider value={ctx}>
      <ReportingDataContext.Provider value={rdx}>
        <Wrapper>
          <StoreSummarySection />
          <VSpace value={3} />
          <StoreRevenueChart />
          <VSpace value={3} />
          <ExperiencesRevenue
            key={`lst${ctx.dateRange}${ctx.startDate}${ctx.endDate}`}
          />
        </Wrapper>
      </ReportingDataContext.Provider>
    </DataPickerContext.Provider>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
