import React, { useRef } from 'react';
import styled from 'styled-components';
import { BaseWelcomeModal } from '@/features/welcome-quiz/shared/base-modal';
import {
  newWelcomeModalContext,
  WelcomeModalContext,
  WelcomeModalStep,
} from '@/features/welcome-quiz/shared/context';
import { WelcomeModalIntro } from '@/features/welcome-quiz/intro';
import { WelcomeModalEmailSetup } from '@/features/welcome-quiz/email-setup';
import { BaseWelcomeModalHeader } from '@/features/welcome-quiz/shared/base-header';
import { WelcomeModalEnableAppEmbed } from '@/features/welcome-quiz/enable-app-embed';
import { WelcomeModalBookDemo } from '@/features/welcome-quiz/book-demo';

export function WelcomeQuiz() {
  const rootRef = useRef(null);
  const ctx = newWelcomeModalContext();
  const { currentStep, footer } = ctx;

  return (
    <WelcomeModalContext.Provider value={ctx}>
      <RefObject ref={rootRef}>
        <BaseWelcomeModal
          fromRef={rootRef}
          header={<BaseWelcomeModalHeader />}
          footer={footer}
        >
          {currentStep === WelcomeModalStep.INTRO && (
            <WelcomeModalIntro rootRef={rootRef} />
          )}
          {currentStep === WelcomeModalStep.EMAIL_SETUP && (
            <WelcomeModalEmailSetup />
          )}
          {currentStep === WelcomeModalStep.ENABLE_APP_EMBED && (
            <WelcomeModalEnableAppEmbed />
          )}
          {currentStep === WelcomeModalStep.BOOK_DEMO && (
            <WelcomeModalBookDemo />
          )}
        </BaseWelcomeModal>
      </RefObject>
    </WelcomeModalContext.Provider>
  );
}

const RefObject = styled.div`
  #shared-elem {
    padding: 0 !important;
  }

  #shared-elem > div {
    padding: 2rem 0 0 0 !important;
    background: white;
  }
`;
export { AppEmbedInstructions } from '@/features/welcome-quiz/shared/components';
