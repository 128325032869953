import { DateRange } from '@/features/dashboard/reports/models';

export function getRangeString(dateRange: DateRange) {
  let message: string;
  switch (dateRange) {
    case DateRange.CUSTOM:
      message = `Custom`;
      break;
    case DateRange.ALL_TIME:
      message = `All Time`;
      break;
    case DateRange.LAST_7_DAYS:
      message = `Last 7 Days`;
      break;
    case DateRange.LAST_30_DAYS:
      message = `Last 30 Days`;
      break;
    case DateRange.LAST_YEAR:
      message = `Last Year`;
      break;
    case DateRange.TODAY:
      message = `Today`;
      break;
    case DateRange.YESTERDAY:
      message = `Yesterday`;
      break;
    default:
      return `Last 7 Days`;
  }
  return message;
}
