import * as React from 'react';
import styled from 'styled-components';
import { useContext } from 'react';
import {
  AssistedRevenueTooltip,
  DirectRevenueTooltip,
  RevenueTooltip,
} from '@/features/dashboard/reports/common';
import { nFormatter } from '@/utils/experience-utils';
import { ReportingDataContext } from '@/features/dashboard/reports/context';
import { currencySymbol } from '@/utils/currencies';
import { MetricKind } from '@/webapi/models';
import { StoreSummary } from '@/features/dashboard/reports/models';
import { NoData } from '@/features/dashboard/reports/NoData';

export function SummaryCard() {
  const symbol = currencySymbol();

  const { loading, storeSummary, nodata } = useContext(ReportingDataContext);
  const directRevenue = sum(storeSummary, MetricKind.DIRECT_REVENUE);
  const assistedRevenue = sum(storeSummary, MetricKind.ASSISTED_REVENUE);
  const revenue = sum(storeSummary, MetricKind.REVENUE);
  if (nodata) {
    return <NoData />;
  }
  return (
    <StoreSummaryCard>
      <Summary>
        <p>
          Revenue through experiences
          <RevenueTooltip />
        </p>
        <span style={{ color: `#8F9DAA` }}>
          {loading ? `...` : `${symbol}${nFormatter(revenue, 2)}`}
        </span>
      </Summary>
      <VerticalSep />
      <Summary borders>
        <p>
          Assisted Revenue
          <AssistedRevenueTooltip />
        </p>
        <span style={{ color: `#78CB74` }}>
          {loading ? `...` : `${symbol}${nFormatter(assistedRevenue, 2)}`}
        </span>
      </Summary>
      <VerticalSep />
      <Summary>
        <p>
          Direct Revenue
          <DirectRevenueTooltip />
        </p>
        <span style={{ color: `#0EB19C` }}>
          {loading ? `...` : `${symbol}${nFormatter(directRevenue, 2)}`}
        </span>
      </Summary>
    </StoreSummaryCard>
  );
}

const VerticalSep = styled.div`
  width: 2.5px;
  height: 100%;
  background-color: #ebf1e8;
`;
const Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Inter, serif;
    letter-spacing: -0.06px;
    color: black;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  padding: 0 4rem;

  span {
    font-size: 3.2rem;
    font-family: 'JetBrains Mono', serif;
    font-weight: 600;
    letter-spacing: -0.86px;
    text-align: center;
    width: 100%;
  }
`;
const StoreSummaryCard = styled.div`
  background-color: #f7fff2;
  padding: 3rem 4rem;
  border: solid 1px #eaeef0;
  border-radius: 4rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15),
    0 14px 36px 0 rgba(90, 201, 172, 0.12), 0 4px 42px 0 rgba(0, 0, 0, 0);

  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;

  cursor: default;
  font-family: 'JetBrains Mono', serif;
  transition: background-color ease-out 0.3s;
  user-select: none;
`;

function sum(storeSummary: StoreSummary, metric: MetricKind) {
  return (
    storeSummary?.metrics?.reduce(
      (previousValue, currentValue) =>
        previousValue +
        (currentValue?.metrics?.find((m) => m.name === metric)?.value || 0),
      0,
    ) || 0
  );
}
