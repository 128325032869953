import * as React from 'react';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { WelcomeModalHintText } from '@/features/welcome-quiz/shared/components';
import { WelcomeModalContext } from '@/features/welcome-quiz/shared/context';
import { Sprintful } from '@/components/sprintful';
import { AccountContext } from '@/features/account-context';

export function WelcomeModalBookDemo() {
  const {
    account: {
      store: { email, firstName, lastName },
    },
    shouldShowBookDemo,
  } = useContext(AccountContext);
  const { setFooter, onCompleteQuiz } = useContext(WelcomeModalContext);

  useEffect(() => {
    if (!shouldShowBookDemo) {
      delayClose(500);
    }
  }, [shouldShowBookDemo]);

  useEffect(() => {
    setFooter(
      <SkipButton onClick={onCompleteQuiz}>
        Skip, start without a demo
      </SkipButton>,
    );
  }, []);

  const delayClose = (timeout = 2000) => {
    setTimeout(() => {
      onCompleteQuiz();
    }, timeout);
  };

  return (
    <>
      <WelcomeModalHintText>
        Get a free 30-minute demo of our easy-to-use experience platform.
      </WelcomeModalHintText>
      <Sprintful
        sendGaEvents
        onConfirmation={delayClose}
        email={email}
        name={`${firstName} ${lastName}`}
      />
    </>
  );
}

const SkipButton = styled.button`
  font-family: Inter, sans-serif;
  appearance: none;
  outline: none;
  text-decoration: underline;
  color: black;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s ease-out;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;
