import { MetricKind } from '@/webapi/models';

export interface MetricValue {
  name: MetricKind;
  value: number;
}
export interface StoreSummary {
  DirectNetRevenue: number;
  DirectOrders: number;
  DirectProducts: number;
  DirectRev: number;
  SessionsWithExperience: number;
  SessionsWithUpsells: number;
  TotalItemsSold: number;
  TotalNetRevenue: number;
  TotalOrders: number;
  TotalRevenue: number;
  TotalSessions: number;
  ViaExpTotalNetRev: number;
  ViaExpTotalOrders: number;
  ViaExpTotalRev: number;
  AssistedRev: number;
  AssistedNetRev: number;
  AssistedOrders: number;
}

export enum DateRange {
  TODAY = `TODAY`,
  YESTERDAY = `YESTERDAY`,
  LAST_7_DAYS = `LAST_7_DAYS`,
  LAST_30_DAYS = `LAST_30_DAYS`,
  LAST_YEAR = `LAST_YEAR`,
  ALL_TIME = `ALL_TIME`,
  CUSTOM = `CUSTOM`,
  UNKNOWN = `UNKNOWN`,
}

export interface Summary {
  useCaseId: string;
  name: string;
  metrics: MetricValue[];
}

export interface UseCaseSummaryEntryV2 {
  experienceName: string;
  orders: number;
  revenue: number;
  netRevenue: number;
  directOrders: number;
  directRevenue: number;
  directNetRevenue: number;
  assistedOrders: number;
  assistedRevenue: number;
  assistedNetRevenue: number;
}

export interface UseCasesSummaryResp {
  summary: UseCaseSummaryEntryV2[];
  total: number;
}

export interface LabelSummeryEntry {
  label: string;
  orders: number;
  revenue: number;
  netRevenue: number;
  directOrders: number;
  directRevenue: number;
  directNetRevenue: number;
  assistedOrders: number;
  assistedRevenue: number;
  assistedNetRevenue: number;
}

export interface LabelSummeryEntryResp {
  summary: LabelSummeryEntry[];
  total: number;
}

export interface ProductSummeryEntry {
  productTitle: string;
  productImgStc: string;
  products: number;
  productId: number;
  revenue: number;
  netRevenue: number;
  directProducts: number;
  directRevenue: number;
  directNetRevenue: number;
  assistedProducts: number;
  assistedRevenue: number;
  assistedNetRevenue: number;
}

export interface ProductsSummaryResp {
  summary: ProductSummeryEntry[];
  total: number;
}
export interface DailyMetrics {
  metrics: DailyChartMetric[];
}

export interface DailyChartMetric {
  day: string;
  viaExpRevenue: number;
  directRevenue: number;
  totalRevenue: number;
  netDirectRevenue: number;
  netTotalRevenue: number;
  netViaExpRevenue: number;
}
