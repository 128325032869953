import React from 'react';

export function Dollar() {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 2</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard-Copy-32" transform="translate(-1776, -332)">
          <g id="Group-3" transform="translate(1360, 291)">
            <g id="Group-2" transform="translate(416, 41)">
              <circle
                id="Oval"
                stroke="#C7C9CC"
                strokeWidth="2"
                cx="13"
                cy="13"
                r="12"
              />
              <text
                id="$"
                fontFamily="LiHeiPro, LiHei Pro"
                fontSize="17"
                fontWeight="400"
                letterSpacing="-0.419100227"
                fill="#C7C9CC"
              >
                <tspan x="8" y="19">
                  $
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
