import React from 'react';
import styled from 'styled-components';
import { Role } from '@/features/teammates/types';

export function roleName(role: Role) {
  switch (role) {
    case Role.Admin:
      return `Admin`;
    case Role.Editor:
      return `Edit + Publish`;
    case Role.Developer:
      return `Edit`;
    case Role.Root:
      return `Owner`;
    case Role.SYSTEM:
      return `System`;
    case Role.Viewer:
      return `View`;
    default:
      return role;
  }
}

const BadgeStyled = styled.p`
  border-radius: 20px;
  border: 1px solid #89959c;
  padding: 5px 10px;
  user-select: none;
  justify-self: end;
  font-size: 1.2rem;
  color: #89959c;
`;

export function Badge({ role }: { role: Role }) {
  return <BadgeStyled>{roleName(role)}</BadgeStyled>;
}
