import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SharedElementOverlayProps } from '@/components/shared-element-overlay';
import { FormInput } from '@/components/floating-label-text-input';
import { RolesSelect } from '@/features/teammates/RoleSelect';
import { BigButton } from '@/components/big-button';
import { ErrMsg } from '@/features/teammates/AddUserPopOver';
import { useCreateUserApi } from '@/features/teammates/useCreateUserApi';

interface AddUserModalProps extends SharedElementOverlayProps {
  hide: () => void;
  refreshUserList: () => void;
}

export function AddUserModalSmall({
  show,
  setShow,
  hide,
  refreshUserList,
}: AddUserModalProps & {
  show: boolean;
  setShow: (b: boolean) => void;
}) {
  const {
    loading,
    ctx,
    onCreateUser,
    errors,
    error,
    setIsSelectOpen,
    register,
    setRole,
  } = useCreateUserApi(hide, refreshUserList);
  const [showDisplay, setShowDisplay] = useState(false);
  const [inLoad, setInLoad] = useState(false);
  const formRef = useRef(null);
  useEffect(() => {
    if (show) setShowDisplay(true);
    else setTimeout(() => setShowDisplay(false), 300);
  }, [show]);
  useEffect(() => {
    if (loading) setInLoad(true);
    else {
      if (inLoad && !error) {
        setShow(false);
        formRef.current.reset();
      }
      setInLoad(false);
    }
  }, [loading, inLoad]);
  return (
    <ModalSmallWrapper showDisplay={showDisplay} show={show}>
      <Content ref={formRef} onSubmit={onCreateUser}>
        <p>Send An Invite</p>
        <FormInput
          errors={errors}
          disabled={loading}
          name="email"
          label="Email"
          type="email"
          register={register}
          required
        />
        <FormInput
          errors={errors}
          disabled={loading}
          name="firstName"
          label="First Name"
          register={register}
          required
        />
        <FormInput
          errors={errors}
          disabled={loading}
          name="lastName"
          label="Last Name"
          register={register}
          required
        />
        <RolesSelect
          currentRole={ctx?.account?.store?.role}
          setIsSelectOpen={setIsSelectOpen}
          onChange={(x) => {
            setRole(x?.value);
          }}
        />
        <ButtonsWrapper>
          <BigButton
            onClick={() => setShow(false)}
            border="1px solid #ddd"
            noTransform
            size="large"
            type="button"
            fillWidth
          >
            Cancel
          </BigButton>
          <BigButton
            type="submit"
            border="2px solid black"
            noTransform
            size="large"
            fillWidth
          >
            Send
          </BigButton>
        </ButtonsWrapper>
        {error && <ErrMsg>Something went wrong..</ErrMsg>}
      </Content>
    </ModalSmallWrapper>
  );
}

const Content = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 25px;
  padding: 2rem;

  p:first-child {
    width: 100%;
    user-select: none;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
  }

  input[type='submit'],
  button {
    margin-top: 20px;
  }
`;

const ModalSmallWrapper = styled.div`
  visibility: ${(p: any) => (p.showDisplay === true ? `visible` : `hidden`)};
  position: absolute;
  z-index: 3;
  width: 400px;
  right: -60px;
  top: 15px;
  scale: 0.8;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 30px;
  opacity: ${(p: any) => (p.show === true ? 1 : 0)};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: ${(p: any) => (p.show === true ? `scale(1)` : `scale(0.8)`)};
  transform-origin: center center;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
    top: -38px;
    right: 23px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  && > button:first-child {
    flex: 1;
    background: white;
    color: black;
  }
  && > button:first-child:hover {
    background: #fcfcfc;
  }
  && > button:nth-child(2) {
    flex: 2;
  }
`;
