import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReportingDataContext } from '@/features/dashboard/reports/context';
import { usePagination } from '@/webapi/use-pagination';
import { useReportsApi } from '@/features/dashboard/reports/api';
import { Pagination } from '@/components/pagination';
import { Card } from '@/components/card';
import { VSpace } from '@/components/spacing';
import { MetricKind } from '@/webapi/models';
import { MetricSelect } from '@/features/dashboard/reports/common';
import { currencySymbol } from '@/utils/currencies';
import { Summary } from '@/features/dashboard/reports/models';
import { Spinner } from '@/Spinner';
import { DataPickerContext } from '@/features/dashboard/shared/date-picker/date-picker';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ExperiencesRevenue: FC = () => {
  const { dateRange } = useContext(DataPickerContext);
  const { nodata } = useContext(ReportingDataContext);
  const { getExperiencesSummary, getExperiencesNames } = useReportsApi();
  const [metric, setMetric] = useState(MetricKind.REVENUE);

  const pageSize = 20;

  const { total, current, loading, page, maxPages, setPage, setArgs } =
    usePagination(getExperiencesSummary, pageSize, {
      dateRange,
      metric,
    });
  const [names, setNames] = useState<any>();

  useEffect(() => {
    setArgs({
      dateRange,
      metric,
    });
  }, [metric]);

  useEffect(() => {
    const ids = current?.summary?.map((x) => x.useCaseId) || [];
    if (ids.length > 0) {
      (async () => {
        const experiencesNames = await getExperiencesNames(ids);
        setNames(experiencesNames.names);
      })();
    }
  }, [page, current]);

  const symbol = currencySymbol();

  if (nodata) {
    return null;
  }

  return (
    <DetailsCard>
      <Container>
        <MetricSelect onChange={(v) => setMetric(v.value)} dataKey={metric} />
        <HeaderRow>
          <div>
            <span>Orders</span>
            <span>{getMetricName(metric)}</span>
          </div>
        </HeaderRow>
        {loading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
        {current?.summary?.map((x, idx) => (
          <Row key={x.useCaseId} bt={idx === 0}>
            <ExpName>{names?.[x.useCaseId]}</ExpName>
            <ExpStats>
              <span>{getMetricOrders(x, metric)}</span>
              <span>{getMetricValue(symbol, x, metric)}</span>
            </ExpStats>
          </Row>
        ))}
        <VSpace value={2} />
        <Pagination
          value={page + 1}
          pageCount={maxPages}
          pageSize={pageSize}
          total={total}
          loading={loading}
          onPageChanged={(page) => setPage(page - 1)}
          label=""
        />
      </Container>
    </DetailsCard>
  );
};
const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderRow = styled.div`
  color: #919fab;
  font-size: 1.4rem;
  font-family: Inter, serif;
  padding-bottom: 2rem;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  div {
    display: flex;
    justify-content: end;
  }
  span {
    text-align: end;
    width: 250px;
  }
`;
const ExpName = styled.div`
  font-family: Inter, serif;
  font-weight: 400;
  color: #42494e;
  font-size: 1.4rem;
  width: 500px;
  text-align: start;
  user-select: text;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const ExpStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, serif;
  color: #42494e;
  font-size: 1.4rem;

  span {
    user-select: text;
    text-align: end;
    width: 250px;
  }
`;
const Row = styled.div`
  border-top: ${(p: any) => (p?.bt ? `2.8px solid #ebebeb` : `none`)};
  border-bottom: 2.8px solid #ebebeb;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DetailsCard = styled(Card)`
  && {
    min-width: 1070px;
    cursor: default;
    padding: 3.5rem;
    border-radius: 4rem;
    justify-content: center;
    align-items: center;
  }
`;

function getMetricValue(symbol: string, x: Summary, metric: MetricKind) {
  return `${symbol}${
    x.metrics?.find((x) => x.name === metric)?.value?.toLocaleString() || 0
  }`;
}

function getMetricName(metric: MetricKind) {
  switch (metric) {
    case MetricKind.REVENUE:
      return `Revenue`;
    case MetricKind.ASSISTED_REVENUE:
      return `Assisted Revenue`;
    case MetricKind.DIRECT_REVENUE:
      return `Direct Revenue`;
    default:
      return metric?.replaceAll(`_`, ``);
  }
}

function getMetricOrders(x: Summary, metric: MetricKind): string {
  switch (metric) {
    case MetricKind.REVENUE:
      return findMetricOrders(x, MetricKind.TRANSACTIONS);
    case MetricKind.ASSISTED_REVENUE:
      return findMetricOrders(x, MetricKind.ASSISTED_TRANSACTIONS);
    case MetricKind.DIRECT_REVENUE:
      return findMetricOrders(x, MetricKind.DIRECT_TRANSACTIONS);
    default:
      return `0`;
  }
}

function findMetricOrders(x: Summary, transactions: MetricKind) {
  return (
    x.metrics?.find((x) => x.name === transactions)?.value?.toLocaleString() ||
    `0`
  );
}
