/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Tooltip } from '@/components/tooltip';
import { MetricKind } from '@/webapi/models';
import { selectStyles } from '@/features/details/charts/assisted-products';

export function RevenueTooltip() {
  return (
    <Tooltip
      text="The total revenue of all purchases made after viewing an experience. Purchases are only counted if they occur if a user viewed an experience. "
      title="Revenue through experiences"
    />
  );
}

export function AssistedRevenueTooltip() {
  return (
    <Tooltip
      text="Revenue generated from products bought after clicking on a recommended item and buying any product within the session."
      title="Assisted Revenue"
    />
  );
}

export function DirectRevenueTooltip() {
  return (
    <Tooltip
      text="Revenue generated from products bought after clicking on a recommended item and buying that item (or items from its group ID) within a 30-day window."
      title="Direct Revenue"
    />
  );
}

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 1rem;
  width: 100%;
`;

const selectOptions = [
  { value: MetricKind.ASSISTED_REVENUE, label: `Assisted revenue` },
  { value: MetricKind.DIRECT_REVENUE, label: `Direct revenue` },
  { value: MetricKind.REVENUE, label: `Revenue through experience` },
];

export function MetricSelect({
  dataKey,
  onChange,
}: {
  onChange: (v) => void;
  dataKey: MetricKind;
}) {
  return (
    <SelectContainer>
      <Select
        menuPlacement="bottom"
        styles={selectStyles}
        defaultValue={{
          value: MetricKind.REVENUE,
          label: `Revenue through experience`,
        }}
        onChange={onChange}
        options={selectOptions}
      />
      {dataKey === MetricKind.REVENUE ? (
        <RevenueTooltip />
      ) : dataKey === MetricKind.DIRECT_REVENUE ? (
        <DirectRevenueTooltip />
      ) : (
        <AssistedRevenueTooltip />
      )}
    </SelectContainer>
  );
}
