import { useCallback, useContext, useMemo } from 'react';
import { AccountContext } from '@/features/account-context';

export function useBookDemo(isLoading: boolean, liveCount: number) {
  try {
    const {
      account: {
        store: { subscription },
      },
      shouldShowBookDemo,
    } = useContext(AccountContext);

    const joinedAt = useMemo(
      () => new Date(subscription?.createdAt || new Date().getTime()),
      [subscription?.createdAt],
    );

    const isNewUser = useCallback(() => {
      const differenceInMs = new Date().getTime() - joinedAt.getTime();
      const differenceInMonths = differenceInMs / (1000 * 60 * 60 * 24 * 30);
      return differenceInMonths < 2;
    }, [joinedAt]);

    const shouldShowBookDemoBox = useMemo(
      () => !isLoading && shouldShowBookDemo && liveCount < 3 && isNewUser(),
      [isLoading, liveCount, isNewUser, shouldShowBookDemo],
    );

    const shouldShowBookDemoHeaderButton = useMemo(
      () => liveCount < 3 && isNewUser() && shouldShowBookDemo,
      [liveCount, isNewUser],
    );

    return {
      shouldShowBookDemoBox,
      shouldShowBookDemoHeaderButton,
    };
  } catch (e) {
    return {
      shouldShowBookDemoBox: false,
      shouldShowBookDemoHeaderButton: false,
    };
  }
}
