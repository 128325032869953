import * as React from 'react';
import { VSpace } from '@/components/spacing';
import { SummaryCard } from '@/features/dashboard/reports/top_section/summary_card';
import { RangePopover } from '@/features/dashboard/shared/date-picker/range_popover';

export function StoreSummarySection() {
  return (
    <>
      <RangePopover />
      <VSpace value={3} />
      <SummaryCard />
    </>
  );
}
