import styled from 'styled-components';
import { breakpoints } from '@/components/responsive';

export const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  padding-top: 3rem;
  max-width: 100%;
  width: 100vw;

  &&:nth-child(even) {
    background: #f6f5f4;
  }

  &&:nth-child(odd) {
    background: white;
  }

  & > * {
    margin-bottom: 3rem;
    ${breakpoints.down(`md`)} {
      margin-bottom: 1.5rem;
    }
  }
`;

export const Padding = styled.div`
  display: inherit;
  flex-direction: inherit;
  justify-items: inherit;
  align-items: inherit;
  justify-content: inherit;
  align-content: inherit;
  padding: ${(props) => props.padding || `0`};
`;

export interface HorizontalLayoutProps {
  gridColumns?: string;
  gap?: string;
  mobileGridColumns?: string;
  maxWidth?: string;
}

export const HorizontalScroll = styled.div`
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  max-width: 100vw;

  & > * {
    width: ${(props) => props.width || `auto`};
    margin-right: ${(props) => props.gap || `4rem`};
  }

  & > *:first-child {
    margin-left: ${(props) => props.gap || `4rem`};
  }
`;

export const HorizontalLayout = styled.div`
  max-width: ${(props: HorizontalLayoutProps) => props.maxWidth || `100%`};
  display: ${(props: HorizontalLayoutProps) =>
    props.gridColumns ? `grid` : `flex`};
  flex-direction: row;
  grid-template-columns: ${(props: HorizontalLayoutProps) => props.gridColumns};
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: ${(props: HorizontalLayoutProps) => (props.gap ? props.gap : `1rem`)};

  ${breakpoints.down(`md`)} {
    display: ${(props: HorizontalLayoutProps) =>
      props.mobileGridColumns ? `grid` : `flex`};

    grid-template-columns: ${(props: HorizontalLayoutProps) =>
      props.mobileGridColumns};

    grid-gap: calc(
      ${(props: HorizontalLayoutProps) => (props.gap ? props.gap : `1rem`)} *
        0.7
    );

    & > * {
      margin-right: 0;
    }
  }

  ${breakpoints.up(`xlr`)} {
    gap: calc(
      ${(props: HorizontalLayoutProps) => (props.gap ? props.gap : `1rem`)} * 2
    );
  }
`;
