import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { IoIosArrowBack } from 'react-icons/io';
import { Picker } from '@/features/details/date-pick/custom-picker';
import { VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { DatePickerChip } from '@/features/dashboard/shared/date-picker/chip';
import { DateRange } from '@/features/dashboard/reports/models';
import { RangeOptions } from '@/features/dashboard/shared/date-picker/options';
import { formatDuration } from '@/features/details/date-pick/chip';
import { DataPickerContext } from '@/features/dashboard/shared/date-picker/date-picker';

type Props = { onClick: () => void; close: () => void };

function DatPickerContainer({ onClick, close }: Props) {
  const {
    setRanges,
    setStartDate,
    setEndDate,
    ranges,
    startDate,
    setDateRange,
    endDate,
  } = useContext(DataPickerContext);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  return (
    <>
      <BackBtn
        onClick={onClick}
        startDate={localStartDate}
        endDate={localEndDate}
      />
      <Picker
        showDateDisplay={false}
        onChange={(r: any) => {
          setRanges([r.rollup]);
          setLocalStartDate(r.rollup.startDate);
          setLocalEndDate(r.rollup.endDate);
        }}
        ranges={ranges}
      />
      <VSpace />
      <BigButton
        onClick={() => {
          setStartDate(localStartDate);
          setEndDate(localEndDate);
          setDateRange(DateRange.CUSTOM);
          close();
        }}
        background="blue"
        noTransform
        size="medium"
        fillWidth
      >
        Apply
      </BigButton>
    </>
  );
}

export const RangePopover: FC = () => {
  const { dateRange, startDate, endDate } = useContext(DataPickerContext);

  const [isOpen, setIsOpen] = useState(false);
  const [showPicker, setShowPicker] = useState(dateRange === DateRange.CUSTOM);
  return (
    <Popover
      onClickOutside={() => setIsOpen(false)}
      isOpen={isOpen}
      positions={[`bottom`]}
      containerStyle={{ zIndex: `30` }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={20}
        >
          <PopoverContainer>
            <div>
              <div style={{ display: showPicker ? `block` : `none` }}>
                <DatPickerContainer
                  close={() => setIsOpen(false)}
                  onClick={() => setShowPicker(!showPicker)}
                />
              </div>
              <div style={{ display: showPicker ? `none` : `block` }}>
                <RangeOptions
                  close={() => setIsOpen(false)}
                  setShowPicker={() => {
                    setShowPicker(true);
                    setIsOpen(true);
                  }}
                />
              </div>
            </div>
          </PopoverContainer>
        </ArrowContainer>
      )}
    >
      <DatePickerChip
        key={`${dateRange}${startDate}${endDate}`}
        onClick={() => setIsOpen(!isOpen)}
      />
    </Popover>
  );
};

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 1rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 22px 154px 0 rgba(0, 0, 0, 0.17),
    0 18px 46px 0 rgba(177, 217, 203, 0.18), 0 8px 24px 0 rgba(0, 0, 0, 0.12);
`;

const Back = styled.button`
  width: 100%;
  font-family: 'JetBrains Mono', serif;
  font-weight: 500;
  border-bottom: 2px solid #acaeb0;
  padding-bottom: 1rem;

  user-select: none;
  span:first-child {
    :hover {
      cursor: pointer;
      opacity: 0.5;
    }
    :active {
      cursor: default;
      opacity: 1;
    }
    display: flex;
    justify-content: start;
    align-items: center;
  }
  span:nth-child(2) {
    margin-left: 3rem;
    font-weight: 400;
    font-size: 12px;
  }

  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 15px;

  margin: 0;
  svg {
    margin-right: 1rem;
  }
`;

type BackBrnProps = {
  onClick: () => void;
  startDate: Date;
  endDate: Date;
};

function BackBtn({ onClick, startDate, endDate }: BackBrnProps) {
  return (
    <Back type="button" onClick={onClick}>
      <span>
        <IoIosArrowBack />
        Custom
      </span>
      <span>{formatDuration(startDate, endDate)}</span>
    </Back>
  );
}
