import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { VscFilter } from 'react-icons/vsc';
import { useDebouncedCallback } from 'use-debounce';
import {
  FilterChip,
  SearchInput,
  SortSelect,
  InviteOptions,
} from './components';
import { ExperienceFilter, ExperienceSort } from '@/webapi/use-experience-api';
import { MyExperiencesContext } from '@/features/dashboard/experiences/context';
import { isBrowser } from '@/utils/browser';
import { CheckboxPopover } from '@/components/checkbox-popover';

export function ExperienceStatusFilter() {
  const {
    filter,
    sort,
    searchText,
    setFilter,
    setSort,
    setSearchText,
    draft,
    abTests,
    oneHundredPercent,
    running,
    archived,
    paused,
    total,
    labelsState,
    setLabelsState,
    labelsGetter,
  } = useContext(MyExperiencesContext);

  const onSelection = (value: ExperienceFilter) => {
    setFilter(value);
  };

  const onSearchTextChanged = (ev) => {
    const text = ev.target.value as string;
    setSearchText(text);
  };

  const onSortChanged = (sort: ExperienceSort) => {
    setSort(sort);
  };

  const debouncedSearch = useDebouncedCallback(onSearchTextChanged, 300);

  const selectedLabels = useMemo(
    () => labelsState?.filter((l) => l.selected),
    [labelsState],
  );

  const labelsCaption = useMemo(() => {
    if (!selectedLabels || selectedLabels?.length === 0) {
      return `By Label`;
    }

    if (selectedLabels?.length === 1) {
      return selectedLabels[0]?.caption;
    }

    return `${selectedLabels?.length} labels`;
  }, [selectedLabels, labelsState]);

  if (!isBrowser()) {
    return null;
  }

  return (
    <Wrapper>
      <FilterChip
        onClick={() => {
          total > 0 && onSelection(ExperienceFilter.UNKNOWN_STATUS);
        }}
        vselected={filter === ExperienceFilter.UNKNOWN_STATUS}
      >
        All ({running + draft + paused})
      </FilterChip>
      <FilterChip
        vdisabled={running === 0}
        onClick={() => {
          running > 0 && onSelection(ExperienceFilter.PUBLISHED);
        }}
        vselected={filter === ExperienceFilter.PUBLISHED}
      >
        Live ({running})
      </FilterChip>
      <FilterChip
        vdisabled={abTests === 0}
        onClick={() => {
          abTests > 0 && onSelection(ExperienceFilter.AB_TEST);
        }}
        vselected={filter === ExperienceFilter.AB_TEST}
      >
        A/B Test ({abTests})
      </FilterChip>
      <FilterChip
        vdisabled={oneHundredPercent === 0}
        onClick={() => {
          oneHundredPercent > 0 &&
            onSelection(ExperienceFilter.ONE_HUNDRED_PERCENT);
        }}
        vselected={filter === ExperienceFilter.ONE_HUNDRED_PERCENT}
      >
        100% ({oneHundredPercent})
      </FilterChip>
      <FilterChip
        vdisabled={draft === 0}
        onClick={() => {
          draft > 0 && onSelection(ExperienceFilter.DRAFT);
        }}
        vselected={filter === ExperienceFilter.DRAFT}
      >
        Draft ({draft})
      </FilterChip>
      <FilterChip
        vdisabled={paused === 0}
        onClick={() => {
          paused > 0 && onSelection(ExperienceFilter.PAUSED);
        }}
        vselected={filter === ExperienceFilter.PAUSED}
      >
        Paused ({paused})
      </FilterChip>
      <FilterChip
        vdisabled={archived === 0}
        onClick={() => {
          archived > 0 && onSelection(ExperienceFilter.ARCHIVED);
        }}
        vselected={filter === ExperienceFilter.ARCHIVED}
      >
        Archived ({archived})
      </FilterChip>
      <CheckboxPopover
        options={labelsState}
        optionsGetter={labelsGetter}
        emptyState="No labels found"
        onChange={(state) => setLabelsState(state)}
      >
        <FilterChip
          className={`${selectedLabels?.length > 0 ? `selected` : ``}`}
        >
          <span className="long-text">{labelsCaption}</span>
          {` `}
          <VscFilter size={14} />
        </FilterChip>
      </CheckboxPopover>
      <SearchInput defaultValue={searchText} grow onChange={debouncedSearch} />
      <SortSelect defaultValue={sort} onSelection={onSortChanged} />
      <InviteOptions />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.3rem;

  input {
    font-size: 1.3rem;
  }

  select {
    font-size: 1.3rem;
  }
`;
