import React, { useEffect, useMemo } from 'react';

export interface SprintfulProps {
  onConfirmation?: (ev) => void;
  sendGaEvents?: boolean;
  handle?: string;
  email?: string;
  name?: string;
}

export function Sprintful({
  sendGaEvents,
  handle,
  onConfirmation,
  email,
  name,
}: SprintfulProps) {
  const query = useMemo(() => {
    let out = ``;
    if (email) {
      out += `&email=${email}`;
    }
    if (name) {
      out += `&name=${name}`;
    }
    return out;
  }, [email, name]);

  useEffect(() => {
    // @ts-ignore
    if (!window?.Sprintful?.iframe) {
      // @ts-ignore
      window?.Sprintful?.init();
    }
  }, []);

  useEffect(() => {
    window.addEventListener(`message`, handler);
    return () => window.removeEventListener(`message`, handler);
  }, []);

  const handler = (ev) => {
    if (ev.data.event && ev.data.event.indexOf(`sprintful-`) > -1) {
      if (ev.data?.payload?.view === `confirmation`) {
        !!sendGaEvents && reportToGA(ev.data?.payload?.view);
        onConfirmation && onConfirmation(ev);
      } else if (ev.data?.payload?.view === `booking`) {
        !!sendGaEvents && reportToGA(ev.data?.payload?.view);
      }
    }
  };

  const reportToGA = (name: string) => {
    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag(`event`, `book_demo_${name}`, {
        context: window.location.pathname,
      });
    }
  };

  return (
    <div
      className="sprintful-inline-widget"
      data-copy-parents-query={false}
      data-url={`https://on.sprintful.com/${
        handle || `visually-io-demo`
      }?hide-logo=true&hide-message=true${query}`}
      style={{ height: `340px`, backgroundColor: `#fff` }}
    />
  );
}
