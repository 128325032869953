/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { HSpace, VSpace } from '@/components/spacing';
import { SummaryCards } from '@/features/dashboard/reports_v2/top_section/summary_card';
import { RangePopover } from '@/features/dashboard/shared/date-picker/range_popover';
import { Dollar } from '@/features/dashboard/reports_v2/top_section/dollar';
import { DownIcon } from '@/features/details/date-pick/chip';

export function StoreSummarySection() {
  return (
    <>
      <Box>
        <RangePopover />
        <HSpace value={4} />
        <TypeSelect />
      </Box>
      <VSpace value={4} />
      <SummaryCards />
    </>
  );
}
const DollarWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 8%;
  opacity: 0.8;

  z-index: 200;
  svg {
    height: 16px;
    width: 16px;
    color: #75808b;
  }
`;
const IndicatorWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 83%;

  z-index: 200;
`;

const SelectWrapper = styled.div`
  position: relative;
  svg {
    height: 14px;
    width: 14px;
    color: #8897a4;
  }
`;
const Box = styled.div`
  display: flex;
  position: relative;
`;
const selectStyles: StylesConfig = {
  menu: (styles) => ({
    ...styles,
    width: `150x`,
  }),
  container: (styles) => ({
    ...styles,
    width: `150x`,
    zIndex: 26,
  }),
  control: (styles) => ({
    ...styles,
    border: `1px solid #ccccce`,
    outline: `none`,
    borderRadius: `20px`,
    backgroundColor: `#f8f9fb`,
    minWidth: `175px`,
    textAlign: `start`,
    paddingLeft: `2.8rem`,
    height: `30px`,
    boxShadow: `0 1px 4px 0 rgba(68,72,80,0.08)`,
    ':hover': {
      border: `1px solid #c9c9cb`,
      opacity: 0.5,
      cursor: `pointer`,
    },
    ':active': {
      border: `1px solid #c9c9cb`,
      opacity: 1,
    },
  }),
  input: (styles) => ({
    ...styles,
    fontSize: `1.3rem`,
    fontFamily: `Jetbrains Mono, serif`,
    fontWeight: 500,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: `1.3rem`,
    fontFamily: `Jetbrains Mono, serif`,
    fontWeight: 500,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: `1.3rem`,
    fontWeight: 500,
    minWidth: `175px`,
    fontFamily: `Jetbrains Mono, serif`,
    background: isSelected ? `lightgray` : `white`,
    ':hover': {
      background: `grey`,
      color: `white`,
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: `#8897a4`,
  }),
  indicatorSeparator: () => ({
    display: `none`,
  }),
  indicatorsContainer: () => ({
    display: `none`,
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: `1.3rem`,
    fontFamily: `Jetbrains Mono, serif`,
    fontWeight: 500,
  }),
};

function TypeSelect() {
  const [net, setNet] = useQueryParam(`n`, BooleanParam);

  return (
    <SelectWrapper>
      <Select
        menuPlacement="bottom"
        styles={selectStyles}
        isSearchable={false}
        defaultValue={
          net
            ? { value: `net`, label: `Net` }
            : { value: `total`, label: `Total` }
        }
        onChange={(x: any) => {
          setNet(x.value === `net`);
        }}
        options={[
          { value: `total`, label: `Total` },
          { value: `net`, label: `Net` },
        ]}
      />
      <DollarWrapper>
        <Dollar />
      </DollarWrapper>
      <IndicatorWrapper>
        <DownIcon />
      </IndicatorWrapper>
    </SelectWrapper>
  );
}
