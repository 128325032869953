import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import {
  DailyMetrics,
  DateRange,
  LabelSummeryEntryResp,
  ProductsSummaryResp,
  StoreSummary,
  UseCasesSummaryResp,
} from '@/features/dashboard/reports_v2/models';
import { MetricKind } from '@/webapi/models';

export function useReportsApi() {
  const { post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  async function getStoreSummary(
    dateRange: DateRange,
    startDate?: Date,
    endDate?: Date,
  ): Promise<StoreSummary> {
    const { endAt, startAt } = getRange(dateRange);
    const isCustom = dateRange === DateRange.CUSTOM;
    return post(routes.storeTotals, {
      startAt: isCustom ? startDate : startAt,
      endAt: isCustom ? endDate : endAt,
    });
  }

  async function getExperiencesSummary(
    page: number,
    size: number,
    args: {
      dateRange: DateRange;
      metric: MetricKind;
      startDate: Date;
      endDate: Date;
    },
  ): Promise<UseCasesSummaryResp> {
    const { endAt, startAt } = getRange(args.dateRange);
    const isCustom = args.dateRange === DateRange.CUSTOM;
    return post(routes.experienceSummaryV2, {
      startAt: isCustom ? args.startDate : startAt,
      endAt: isCustom ? args.endDate : endAt,
      page,
      size,
      sort: args.metric,
    });
  }
  async function getStoreDailySummary(
    dateRange: DateRange,
    startDate?: Date,
    endDate?: Date,
  ): Promise<DailyMetrics> {
    const { endAt, startAt } = getRange(dateRange);
    const isCustom = dateRange === DateRange.CUSTOM;
    return post(routes.storeDailySummary, {
      startAt: isCustom ? startDate : startAt,
      endAt: isCustom ? endDate : endAt,
    });
  }

  async function getLabelsSummary(
    page: number,
    size: number,
    args: {
      dateRange: DateRange;
      metric: MetricKind;
      startDate: Date;
      endDate: Date;
    },
  ): Promise<LabelSummeryEntryResp> {
    const { endAt, startAt } = getRange(args.dateRange);
    const isCustom = args.dateRange === DateRange.CUSTOM;
    return post(routes.labelSummary, {
      startAt: isCustom ? args.startDate : startAt,
      endAt: isCustom ? args.endDate : endAt,
      page,
      size,
      sort: args.metric,
    });
  }

  async function getProductsSummary(
    page: number,
    size: number,
    args: {
      dateRange: DateRange;
      metric: MetricKind;
      startDate: Date;
      endDate: Date;
    },
  ): Promise<ProductsSummaryResp> {
    const { endAt, startAt } = getRange(args.dateRange);
    const isCustom = args.dateRange === DateRange.CUSTOM;
    return post(routes.productsSummary, {
      startAt: isCustom ? args.startDate : startAt,
      endAt: isCustom ? args.endDate : endAt,
      page,
      size,
      sort: args.metric,
    });
  }

  return {
    loading,
    getLabelsSummary,
    getProductsSummary,
    getStoreSummary,
    getExperiencesSummary,
    getStoreDailySummary,
  };
}

export function getRange(dateRange: DateRange) {
  let endAt = new Date();
  let startAt: Date;
  const oneDay = 8.64e7;
  switch (dateRange) {
    case DateRange.LAST_7_DAYS:
      startAt = new Date(endAt.valueOf() - oneDay * 7);
      break;
    case DateRange.LAST_30_DAYS:
      startAt = new Date(endAt.valueOf() - oneDay * 30);
      break;
    case DateRange.TODAY:
      startAt = endAt;
      break;
    case DateRange.YESTERDAY:
      startAt = new Date(endAt.valueOf() - oneDay);
      startAt.setUTCHours(0, 0, 0, 0);
      endAt = startAt;
      endAt.setUTCHours(23, 59, 59, 999);
      break;
    case DateRange.LAST_YEAR:
      startAt = new Date(endAt.valueOf() - oneDay * 365);
      break;
    case DateRange.ALL_TIME:
      startAt = new Date(endAt.valueOf() - oneDay * 365 * 100);
      break;
    case DateRange.CUSTOM:
      break;
    default:
      startAt = new Date(endAt.valueOf() - oneDay * 7);
  }
  return { endAt, startAt };
}
