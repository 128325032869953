import * as React from 'react';
import { useContext } from 'react';
import { currencySymbol } from '@/utils/currencies';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import {
  formatNumber,
  Metric,
  MetricVal,
} from '@/features/dashboard/reports_v2/common';
import { maybe } from '@/features/details/utils';
import { NoDataMsg } from '@/features/dashboard/reports_v2/NoData';
import { MetricsGrid } from '@/features/dashboard/reports_v2/top_section/shared';

export function RevThroughExpMetrics() {
  const symbol = currencySymbol();
  const { storeSummary: data } = useContext(ReportingDataContext);
  if (data == null || data?.SessionsWithExperience === 0) {
    return <NoDataMsg />;
  }
  return (
    <MetricsGrid>
      <Metric>
        <p>
          Sessions with <br />
          Visually experiences
        </p>
        <MetricVal>{formatNumber(data?.SessionsWithExperience)}</MetricVal>
      </Metric>
      <Metric>
        <p>
          Orders Through <br /> Visually Experiences
        </p>
        <MetricVal>{formatNumber(data?.ViaExpTotalOrders)}</MetricVal>
      </Metric>
      <Metric>
        <p>
          Assisted <br /> Revenue
        </p>
        <MetricVal>
          {symbol}
          {formatNumber(data?.AssistedRev)}
        </MetricVal>
      </Metric>
      <Metric>
        <p>AOV</p>
        <MetricVal>
          {symbol}
          {formatNumber(
            maybe(
              () =>
                Math.round(
                  (data!.ViaExpTotalRev / data!.ViaExpTotalOrders) * 100,
                ) / 100,
            ),
          )}
        </MetricVal>
      </Metric>
      <Metric>
        <p>Revenue</p>
        <MetricVal>
          {symbol}
          {formatNumber(data?.ViaExpTotalRev)}
        </MetricVal>
      </Metric>
    </MetricsGrid>
  );
}
