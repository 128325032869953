import React, { FC, useContext, useEffect } from 'react';
import {
  ByDimProps,
  Container,
  DetailsCard,
  ExpName,
  ExpStats,
  getMetricName,
  getOrders,
  getPercentage,
  getRevenue,
  HeaderRow,
  pageSize,
  Row,
} from '@/features/dashboard/reports_v2/lists/shared';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { usePagination } from '@/webapi/use-pagination';
import { currencySymbol } from '@/utils/currencies';
import { VSpace } from '@/components/spacing';
import { Pagination } from '@/components/pagination';
import { getQueryDate } from '@/features/dashboard/reports/context';
import { ListHeader } from '@/features/dashboard/reports_v2/lists/header';

export const ByExperience: FC<ByDimProps> = ({
  metric,
  typeOpt,
  setypeOptT,
  setOpen,
  open,
}) => {
  const { getExperiencesSummary } = useReportsApi();
  const {
    storeSummary: data,
    net,
    dateRange,
    startDate,
    endDate,
  } = useContext(ReportingDataContext);
  const { total, current, loading, page, maxPages, setPage, setArgs } =
    usePagination(getExperiencesSummary, pageSize, {
      dateRange,
      metric,
      startDate: getQueryDate(`s`),
      endDate: getQueryDate(`e`),
    });

  useEffect(() => {
    const startDate = getQueryDate(`s`);
    const endDate = getQueryDate(`e`);
    setArgs({
      dateRange,
      metric,
      startDate,
      endDate,
    });
  }, [dateRange, startDate, endDate]);
  const symbol = currencySymbol();
  return (
    <DetailsCard loading={loading} open={open}>
      <Container>
        <ListHeader
          dataKey={metric}
          typeOpt={typeOpt}
          setypeOptT={setypeOptT}
          open={open}
          setOpen={setOpen}
        />
        {open && (
          <>
            <HeaderRow>
              <div>
                <span>Orders</span>
                <span>{getMetricName(metric)}</span>
                <span>Percent</span>
              </div>
            </HeaderRow>
            {current?.summary?.map((x, idx) => (
              <Row key={x.experienceName} bt={idx === 0}>
                <ExpName>{x.experienceName}</ExpName>
                <ExpStats>
                  <span>{getOrders(x, metric)}</span>
                  <span>
                    {symbol}
                    {Math.round(getRevenue(x, metric, net)).toLocaleString()}
                  </span>
                  <span>{getPercentage(x, metric, net, data).toFixed(2)}%</span>
                </ExpStats>
              </Row>
            ))}
            <VSpace value={2} />
            <Pagination
              value={page + 1}
              pageCount={maxPages}
              pageSize={pageSize}
              total={total}
              loading={loading}
              onPageChanged={(page) => setPage(page - 1)}
              label=""
            />
          </>
        )}
        {` `}
      </Container>
    </DetailsCard>
  );
};
