import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import {
  DataPickerContext,
  newDatePickerCtx,
} from '@/features/dashboard/shared/date-picker/date-picker';
import { RangePopover } from '@/features/dashboard/shared/date-picker/range_popover';
import { PrimaryAudienceModal } from '@/features/dashboard/audiences/modal';
import {
  AudiencesContext,
  newAudiencesCtx,
} from '@/features/dashboard/audiences/context';
import { AudiencesList } from '@/features/dashboard/audiences/audiencesList';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { ConfirmModal } from '@/components/confirm-modal';

export function Audiences() {
  const datePickerCtx = newDatePickerCtx();
  const fromRef = useRef(null);
  const audiencesCtx = newAudiencesCtx();

  return (
    <DataPickerContext.Provider value={datePickerCtx}>
      <AudiencesContext.Provider value={audiencesCtx}>
        <PrimaryAudienceModal fromRef={fromRef} />
        <Wrapper>
          <SubTitle ref={audiencesCtx.confirmModalRef}>
            Create and manage primary audiences, view their data, and seamlessly
            select them for future experiments
          </SubTitle>
          <VSpace value={3} />
          <RangePickerWrapper>
            <RangePopover />
          </RangePickerWrapper>
          <VSpace value={4} />
          <Grid>
            <AudiencesList fromRef={fromRef} />
          </Grid>
        </Wrapper>
        <DeleteAreYou />
      </AudiencesContext.Provider>
    </DataPickerContext.Provider>
  );
}

const RangePickerWrapper = styled.div`
  margin: 0 auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
`;

const SubTitle = styled.p`
  text-align: center;
  font-family: 'Eesti', serif;
  font-weight: 300;
  font-size: 1.6rem;
  color: black;
  line-height: 2;
  user-select: none;
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function DeleteAreYou() {
  const ctx = useContext(AudiencesContext);
  return (
    <SharedElementOverlay {...ctx.sharedOverlayProps}>
      <ConfirmModal
        title="Are you sure?"
        description="Are you sure you want to delete?"
        onConfirm={ctx.audienceDelete}
        onClose={ctx.hideConfirmModal}
        onDiscard={ctx.hideConfirmModal}
        yesCaption="Delete"
        noCaption="Do not delete"
      />
    </SharedElementOverlay>
  );
}
