import React, { FC } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Card } from '@/components/card';
import { VSpace } from '@/components/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  p {
    text-align: center;
  }
`;

const DetailsCard = styled(Card)`
  && {
    min-width: 1070px;
    min-height: 500px;
    cursor: default;
    padding: 3.5rem;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

export const NoData: FC = () => (
  <>
    <VSpace value={4} />
    <DetailsCard>
      <Container>
        <VSpace value={2} />
        <div>
          <StaticImage
            src="bulb.svg"
            alt="no_data"
            placeholder="none"
            loading="eager"
            formats={[`png`]}
            height={80}
          />
        </div>
        <VSpace value={2} />
        <NoDataMsg />
      </Container>
    </DetailsCard>
  </>
);

const SubTitle = styled.p`
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 2rem;
  color: #a6afb8;
`;
export function NoDataMsg() {
  const havenT = `haven't`;

  return (
    <SubTitle>
      We {havenT} collected enough data yet. Please
      <br /> enter again in the next few days
    </SubTitle>
  );
}
