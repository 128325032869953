import { useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import { useAuthApi, UserDetails } from '@/webapi/use-auth-api';
import { hash } from '@/utils/cache';
import { maybe } from '@/features/details/utils';

export function useUserTrack(init = false) {
  const { getUserDetails } = useAuthApi();
  const [data, setData] = useState<UserDetails | undefined>(undefined);

  useEffect(() => {
    try {
      getUserDetails().then((details) => {
        setData(details);
      });
    } catch (ex) {
      console.error(`vsly`, ex);
    }
  }, []);

  const updateLogRocket = (userFields?: any, companyFields?: any) => {
    if (!data?.email) return;
    const obj = {
      email: data.email,
      created_at: data.signupDateSeconds,
      name: `${data.firstName} ${data.lastName}`,
      alias: data.storeAlias,
      remainingTrailDays: data.daysToTrialEnd,
      ordersCount: data.ordersCount || 0,
      website: data.storeDomain,
      ...companyFields,
      ...userFields,
    };
    console.debug(
      `vsly-logrocket`,
      `updating user to: `,
      hash(data.email),
      obj,
    );
    LogRocket.identify(hash(data.email), obj);
  };

  const updateUser = (userFields?: any, companyFields?: any) => {
    maybe(() => updateLogRocket(userFields, companyFields));
  };

  useEffect(() => {
    if (data && init) {
      updateUser();
    }
  }, [data]);

  return {
    updateUser,
    data,
  };
}
