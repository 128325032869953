import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@/components/responsive';

export function NewExperienceTile({ onClick }: { onClick: () => void }) {
  return <Wrapper onClick={onClick}>Create New Experience</Wrapper>;
}

const Wrapper = styled.div`
  user-select: none;
  background: rgba(72, 173, 252, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 1.5rem;
  color: #35a2f8;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.06), 0 4px 8px 0 rgb(7, 6, 6, 0.04);
  width: 87%;
  ${breakpoints.up(1500)} {
    width: 77%;
  }
  height: 8rem;
  padding: 1rem 4rem;

  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  font-family: 'Inter', serif;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: background-color 0.5s ease-out;

  :hover {
    rgba(72, 173, 252, 0.02)
  }
`;
