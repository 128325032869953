import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';

export function useListUsers() {
  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  const listUsers = async (page: number, size: number) =>
    post(routes.listUsers(), {
      page,
      limit: size,
    });

  return {
    error,
    loading,
    listUsers,
  };
}
