import styled from 'styled-components';
import React, { useContext, useMemo } from 'react';
import { TinyProgressBar } from '@/components/tiny-progress-bar';
import {
  WelcomeModalContext,
  WelcomeModalStep,
} from '@/features/welcome-quiz/shared/context';

export function BaseWelcomeModalHeader() {
  const { progress, currentStep } = useContext(WelcomeModalContext);

  const smallHeader = useMemo(() => {
    if (currentStep === WelcomeModalStep.BOOK_DEMO) {
      return `READY TO GO`;
    }
    if (currentStep === WelcomeModalStep.ENABLE_APP_EMBED) {
      return `IN ORDER TO GRANT VISUALLY.IO ACCESS TO YOUR THEME`;
    }
    return `WELCOME TO VISUALLY.IO ENJOY YOUR EXPERIENCE`;
  }, [currentStep]);

  const bigHeader = useMemo(() => {
    if (currentStep === WelcomeModalStep.BOOK_DEMO) {
      return `Experience Visually.io firsthand, book a demo`;
    }
    if (currentStep === WelcomeModalStep.ENABLE_APP_EMBED) {
      return `Enable Visually.io as App Embed`;
    }
    return `Let's get to know you first, a few quick questions!`;
  }, [currentStep]);

  return (
    <Wrapper>
      <SubHeader currentStep={currentStep}>{smallHeader}</SubHeader>
      <Header>{bigHeader}</Header>
      <TinyProgressBar
        initialValue={progress}
        heightInRem={0.5}
        widthInPercent={60}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  grid-gap: 2rem;

  .vsly-pb-wrapper {
    margin-top: 0.8rem;
  }

  .vsly-pb-value {
    background: #61adff;
  }
`;

const SubHeader = styled.span`
  width: 100%;
  font-family: Inter, serif;
  font-size: 1.2rem;
  color: ${(props: any) =>
    props.currentStep === WelcomeModalStep.BOOK_DEMO ? `#23D011` : `#7d94a4`};
  letter-spacing: -0.37px;
  text-align: center;
  font-weight: ${(props: any) =>
    props.currentStep === WelcomeModalStep.BOOK_DEMO
      ? `700`
      : `500`} !important;
  text-transform: uppercase;
`;

const Header = styled.span`
  width: 100%;
  font-family: Inter, serif;
  font-size: 2.4rem !important;
  color: black;
  letter-spacing: -0.37px;
  text-align: center;
  font-weight: 800 !important;
`;
