import React, { useContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import { useExperienceApi, VariantPreview } from '@/webapi/use-experience-api';
import { ExperienceSpinner } from '@/components/experience-spinner';
import { BigButton } from '@/components/big-button';
import { openLinkInNewTab } from '@/utils/browser';
import { AccountContext } from '@/features/account-context';

export interface PermanentPreviewModalProps {
  overlayProps: SharedElementOverlayProps;
  experienceId: string;
  themeId: number;
  mainThemeId: number;
  hide: () => void;
}

export function PermanentPreviewModal({
  experienceId,
  themeId,
  mainThemeId,
  overlayProps,
  hide,
}: PermanentPreviewModalProps) {
  const {
    account: {
      store: { domain },
    },
  } = useContext(AccountContext);

  const { generatePermanentPreviewLinks, loading } = useExperienceApi(
    undefined,
    undefined,
    true,
  );

  const [links, setLinks] = useState([]);

  useAsyncEffect(async () => {
    const result = await generatePermanentPreviewLinks(
      experienceId,
      domain,
      themeId,
      mainThemeId,
    );
    if (result.links.length === 1) {
      openPreviewLink(result.links[0]);
    }
    setLinks(result?.links);
  }, []);

  const openPreviewLink = (item: VariantPreview) => {
    const url = new URL(item.link);
    url.searchParams.delete(`preview_theme_id`);
    openLinkInNewTab(url.toString(), item.id);
    hide();
  };

  return (
    <SharedElementOverlay {...overlayProps} heightMultiplier={1}>
      <Wrapper>
        <Title>
          {loading
            ? `Generating Preview Link...`
            : `Which variation you want to preview?`}
        </Title>
        {loading && (
          <Center>
            <ExperienceSpinner size={4} />
          </Center>
        )}
        {!loading && (
          <List>
            {links.map((item) => (
              <BigButton
                key={item.id}
                border="2px solid #C8CACB"
                background="white"
                color="#97A0A8"
                noShadow
                onClick={() => openPreviewLink(item)}
              >
                {item.name}
              </BigButton>
            ))}
          </List>
        )}
      </Wrapper>
    </SharedElementOverlay>
  );
}

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Center = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-gap: 1rem;
  overflow-y: scroll;
  max-height: 20rem;
`;

const Title = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
`;
