import { CachePolicies, useFetch } from 'use-http';
import { useEffect, useState } from 'react';
import { routes } from '@/webapi/routes';
import { usePagination } from '@/webapi/use-pagination';

export interface InspirationsListingOpts {
  search?: string;
  tag?: string;
}

export function useInspirationsListing(pageSize = 6) {
  const [tag, setTag] = useState(`*`);
  const [search, setSearch] = useState(``);

  const { post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const listInspirations = async (
    page: number,
    pageSize: number,
    options: InspirationsListingOpts,
  ): Promise<InspirationResponse> => {
    const searchText = options.search || ``;
    let tag = options.tag || `*`;
    if (tag.toLowerCase() === `all`) tag = `*`;
    const req = { page, pageSize, searchText, tag };
    const resp = await post(routes.getInspirations(), req);
    return resp as InspirationResponse;
  };

  const pagination = usePagination(listInspirations, pageSize, { search, tag });

  useEffect(() => {
    pagination.setArgs({ search, tag });
  }, [tag, search]);

  return { ...pagination, setSearch, setTag, tag, search, loading };
}

export interface InspirationResponse {
  inspirations: Array<Inspiration>;
  total: number;
  progress: number;
}

export interface InspirationStatusResponse {
  seen: boolean;
}

export interface Inspiration {
  id: string;
  coverImage: string;
  title: string;
  description: string;
  url: string;
  uplift: string;
  tags: string[];
}
