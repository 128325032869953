import Select, { StylesConfig } from 'react-select';
import React, { useEffect } from 'react';
import { Role } from '@/utils/definitions';
import { roleName } from '@/features/teammates/Badge';

const colourStyles: StylesConfig<any> = {
  menu: (styles) => ({
    ...styles,
  }),
  control: (styles, { isFocused, menuIsOpen }) => ({
    ...styles,
    border: isFocused || menuIsOpen ? `1px solid black` : `1px solid #aaaaaa`,
    outline: `none`,
    marginBottom: `10px`,
    borderRadius: `10px`,
    height: `48px`,
    boxShadow: `none`,
    ':hover': {
      border: `1px solid black`,
    },
    ':active': {
      border: `1px solid black`,
    },
  }),
  input: (styles) => ({
    ...styles,
    fontSize: `14px`,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: `14px`,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: `14px`,
    background: isSelected ? `lightgray` : `white`,
    ':hover': {
      background: `grey`,
      color: `white`,
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: `14px`,
  }),
};

export const RolesSelect = ({
  setIsSelectOpen,
  onChange,
  currentRole,
  menuPlacement,
}: {
  setIsSelectOpen: (boolean) => void;
  onChange: (x: any) => void;
  currentRole: Role;
  menuPlacement?: 'top' | 'bottom';
}) => {
  const options = [
    { value: `admin`, label: roleName(Role.Admin) },
    { value: `viewer`, label: roleName(Role.Viewer) },
    { value: `editor`, label: roleName(Role.Editor) },
    { value: `developer`, label: roleName(Role.Developer) },
  ];
  if (currentRole === `system`) {
    options.push({ value: `system`, label: roleName(Role.SYSTEM) });
  }

  useEffect(() => {
    onChange(options.find((option) => option.value === `editor`));
  }, []);
  return (
    <Select
      required
      menuPlacement={menuPlacement || `top`}
      isSearchable
      styles={colourStyles}
      defaultValue={options.find((option) => option.value === `editor`)}
      onChange={onChange}
      onMenuOpen={() => setIsSelectOpen(true)}
      onMenuClose={() => setIsSelectOpen(false)}
      options={options}
    />
  );
};
