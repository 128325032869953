import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import { InspirationOrigin } from '@/features/inspirations/listing-page';

export interface LongInspirationTileProps {
  id: string;
  title: string;
  image: string;
  description: string;
  uplift: string;
  url: string;
  origin: InspirationOrigin;
}

export function LongInspirationTile({
  id,
  title,
  image,
  description,
  uplift,
  url,
  origin,
}: LongInspirationTileProps) {
  const onClick = () => {
    navigate(`/inspiration?url=${btoa(url)}&id=${id}&bt=${origin}`);
  };

  return (
    <Container className="long-inspirations-tile" onClick={onClick}>
      <img src={image} alt={description} />
      <Content>
        <span className="title">{title}</span>
        <span className="description">{description}</span>
        <span className="uplift">
          <StaticImage
            src="./uplift.png"
            alt=""
            width={15}
            loading="eager"
            placeholder="none"
          />
          {uplift}
        </span>
      </Content>
      <ReadMore>Read More {`>`}</ReadMore>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  min-height: 16rem;
  grid-template-columns: 12rem 1fr 13rem;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.09), 0 2px 8px 0 rgb(7, 6, 6, 0.04);
  border: 1px solid rgba(151, 151, 151, 0.2);
  border-radius: 1rem;
  font-family: 'Eesti', serif;
  grid-gap: 4rem;
  cursor: pointer;

  && > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  text-align: start;
  justify-content: center;
  font-size: 1.7rem;
  line-height: 1.5;
  font-weight: lighter;
  color: black;

  .title {
    font-weight: bold;
  }

  .description {
    font-size: 1.3rem;
  }

  .uplift {
    font-weight: normal;
    font-size: 1.3rem;
    color: #3b47ff;
    display: flex;
    gap: 0.7rem;
  }

  .gatsby-image-wrapper {
    align-self: center;
    justify-self: center;
  }
`;

const ReadMore = styled.button`
  font-family: 'Eesti', serif;
  appearance: none;
  border: none;
  outline: none;
  background: rgba(99, 112, 124, 0.1);
  height: fit-content;
  align-self: center;
  justify-self: flex-start;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 1rem 1.8rem;
  border-radius: 2rem;
  color: rgb(99, 113, 124);
  font-weight: bold;

  transition: opacity 0.2s;

  &&:hover {
    opacity: 0.8;
  }

  &&:active {
    opacity: 0.6;
  }
`;
