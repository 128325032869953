import React from 'react';
import styled from 'styled-components';
import { InspirationsListingPage } from '@/features/inspirations/listing-page';

export function Inspirations() {
  return (
    <Wrapper>
      <InspirationsListingPage pageSize={12} origin="inspirations" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  #inspirations {
    background: transparent;
    padding: 0;
    box-shadow: none;
    border: none;
    margin-top: -3rem;

    .long-inspirations-tile {
      background: white;
    }
  }

  #inspirations > h3:first-of-type {
    display: none;
  }
`;
