import React, { useContext } from 'react';
import styled from 'styled-components';
import { BsPlusLg } from 'react-icons/bs';
import { Role } from '@/utils/definitions';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { AddUserModal } from '@/features/teammates/AddUserModal';
import { AccountContext } from '@/features/account-context';

export const ErrMsg = styled.div`
  margin: 10px;
  color: red;
  font-size: 2rem;
`;

type P = { allowed: boolean };
const PlusStyled = styled.button`
  border: none;
  background: none;
  display: flex;
  margin-top: 20px;
  width: 200px;
  opacity: ${(p: P) => (p?.allowed ? 1 : 0.5)};

  div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    background-color: #f1f3f4;

    p {
      padding-bottom: 6px;
      height: 4rem;
      font-size: 4rem;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: center;
    color: #48adfc;
    user-select: none;
    height: 1rem;
    margin-top: 20px;
    margin-left: 2rem;
    font-weight: 700;
    font-size: 1.5rem;
  }

  &:hover {
    cursor: ${(p: P) => (p?.allowed ? `pointer` : `default`)};
    opacity: ${(p: P) => (p?.allowed ? 0.8 : `auto`)};
  }

  &:active {
    cursor: default;
    opacity: ${(p: P) => (p?.allowed ? 1 : `auto`)};
  }
`;

function hasPerms(role?: Role): boolean {
  return role === Role.Root || role === Role.Admin || role === Role.SYSTEM;
}

function AddAnother({ onClick }: { onClick: () => void }) {
  const ctx = useContext(AccountContext);
  const allowed = hasPerms(ctx?.account?.store?.role);
  return (
    <PlusStyled allowed={allowed} onClick={allowed ? onClick : () => 0}>
      <div>
        <BsPlusLg color="#48adfc" size={20} />
      </div>
      <p> Add another</p>
    </PlusStyled>
  );
}

export function useAddUserModalProps() {
  const { props, show, hide, fromRef } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: 0,
      },
      extraTo: {
        background: `white`,
        opacity: 1,
      },
    },
    undefined,
    () => centered(23, 42, 0.7),
  );
  return {
    props,
    show,
    hide,
    fromRef,
  };
}

export function AddUserPopover({ refreshList }: { refreshList: () => void }) {
  const { props, hide, show, fromRef } = useAddUserModalProps();

  return (
    <div ref={fromRef}>
      <AddUserModal {...props} hide={hide} refreshUserList={refreshList} />
      <AddAnother onClick={show} />
    </div>
  );
}
