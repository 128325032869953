import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Section } from '@/features/new-landing-page/components/section';
// @ts-ignore
import { VSpace } from '@/components/spacing';
import { routes } from '@/webapi/routes';
import { DemoVideoDashboardLegend } from '@/features/dashboard/demo/demo-video-dashboard-legend';
import { awaitCondition } from '@/utils/sync';

export function DemoDashboardSection() {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [videoElem, setVideoElem] = useState<HTMLVideoElement | undefined>();

  useEffect(() => {
    if (ref?.current) {
      awaitCondition(
        () => !!ref?.current?.contentDocument?.querySelector?.(`video`),
        500,
        20,
      )
        .then(() => {
          try {
            const video =
              ref?.current?.contentDocument?.querySelector?.(`video`);
            if (video) {
              setVideoElem(video);
            }
          } catch (_) {
            console.error(``);
          }
        })
        .catch(() => {
          console.error(``);
        });
    }
  }, [ref]);

  useEffect(() => {
    if (videoElem) {
      videoElem.volume = 0.5;
    }
  }, [videoElem]);

  return (
    <CustomSection>
      <ContentBlock>
        <DemoDashboardTitle>Unsure where to begin?</DemoDashboardTitle>
        <DemoDashboardSubtitle>
          Explore the full potential of our platform in action by watching
          Visually&apos;s Optimization & Personalization demo
        </DemoDashboardSubtitle>
        <VSpace value={1} />
        <DemoVideoDashboardLegend videoElem={videoElem} />
      </ContentBlock>
      <VideoWrapper>
        <BackgroundOverlay className="only-desktop" />
        <iframe
          ref={ref}
          id="demo-video-iframe"
          title="Visually Product Demo"
          src={routes.cleanQuickPreview(
            `https://iframe.mediadelivery.net/embed/169819/a05f5e18-0be2-4225-96bb-789b36dd47b2?autoplay=false&loop=false&muted=false&preload=true`,
          )}
          loading="lazy"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        />
      </VideoWrapper>
    </CustomSection>
  );
}

const CustomSection = styled(Section)`
  background: black !important;
  display: flex;
  margin-bottom: -5rem;
  box-shadow: 0 2px 15px 2px rgba(214, 18, 221, 0.82);
  border-radius: 3rem 3rem 0 0;
  flex-direction: row;
  height: 37vw;
  min-height: 500px;
  overflow: hidden;
`;

const VideoWrapper = styled.div`
  display: flex;
  aspect-ratio: 16/9;
  align-self: flex-start;
  margin: 2% 6% 6% 5%;
  flex: 1;
  position: relative;
  border-radius: 2rem;
  z-index: 0;

  iframe {
    background: url('https://vz-c7848e4e-e4e.b-cdn.net/a05f5e18-0be2-4225-96bb-789b36dd47b2/thumbnail_0931da1f.jpg');
    background-size: cover;
    border-radius: 2rem;
    overflow: hidden;
    appearance: none;
    border: none;
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0 0 0 0;
    box-shadow: 0 0px 15px 5px rgba(18, 31, 221, 0.82);
  }
`;

const BackgroundOverlay = styled.div`
  z-index: -1;
  position: absolute;
  top: -5rem;
  width: 100vw;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
const DemoDashboardTitle = styled.div`
  color: white;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 4rem;
  text-align: left;
  font-family: 'Eesti', serif;
`;
const DemoDashboardSubtitle = styled.div`
  color: white;
  font-size: 1.8rem;
  font-weight: normal;
  text-align: left;
  line-height: 4rem;
  font-family: 'Eesti', serif;
`;

const ContentBlock = styled.div`
  width: 38%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 5rem 0 5rem 8rem;
  overflow-y: auto;
  height: 100%;
`;
