import * as React from 'react';
import styled from 'styled-components';
import { StoreSummarySection } from '@/features/dashboard/reports_v2/top_section';
import {
  newReportDataCtx,
  ReportingDataContext,
} from '@/features/dashboard/reports_v2/context';
import { VSpace } from '@/components/spacing';
import { ExperiencesRevenue } from '@/features/dashboard/reports_v2/lists';
import {
  DataPickerContext,
  newDatePickerCtx,
} from '@/features/dashboard/shared/date-picker/date-picker';
import { MetricKind } from '@/webapi/models';
import { NoData } from '@/features/dashboard/reports_v2/NoData';
import { RangePopover } from '@/features/dashboard/shared/date-picker/range_popover';
import { DailySummaryChart } from '@/features/dashboard/reports_v2/chart/revenue_chart';

export function ReportsV2() {
  const ctx = newDatePickerCtx();
  const rdx = newReportDataCtx();
  if (rdx?.storeSummary?.TotalRevenue === 0) {
    return (
      <DataPickerContext.Provider value={ctx}>
        <RangePopover />
        <NoData />;
      </DataPickerContext.Provider>
    );
  }

  return (
    <DataPickerContext.Provider value={ctx}>
      <ReportingDataContext.Provider value={rdx}>
        <Wrapper>
          <StoreSummarySection />
          <VSpace value={3} />
          <DailySummaryChart />
          <VSpace value={3} />
          <ExperiencesRevenue metric={MetricKind.DIRECT_REVENUE} />
          <VSpace value={3} />
          <ExperiencesRevenue metric={MetricKind.ASSISTED_REVENUE} />
          <VSpace value={3} />
          <ExperiencesRevenue metric={MetricKind.REVENUE} />
        </Wrapper>
      </ReportingDataContext.Provider>
    </DataPickerContext.Provider>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
