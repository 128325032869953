import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  RoundButton,
  WelcomeModalHintText,
} from '@/features/welcome-quiz/shared/components';
import { AccountContext } from '@/features/account-context';
import { Card } from '@/components/card';
import {
  WelcomeModalContext,
  WelcomeModalStep,
} from '@/features/welcome-quiz/shared/context';
import { TextInput } from '@/components/text-input';
import { VSpace } from '@/components/spacing';
import { CloseButton } from '@/components/close-button';
import { useInviteUser } from '@/features/welcome-quiz/email-setup/use-invite-user';
import { validateEmail } from '@/features/details/utils';
import { ExperienceSpinner } from '@/components/experience-spinner';

export function WelcomeModalEmailSetup() {
  const {
    account: {
      store: { email },
    },
  } = useContext(AccountContext);

  const { inviteAdminUser, loading, error } = useInviteUser();
  const { setCurrentStep, setFooter } = useContext(WelcomeModalContext);
  const [isInviting, setIsInviting] = useState(false);
  const [fullname, setFullname] = useState(``);
  const [inviteeEmail, setInviteeEmail] = useState(``);
  const [errorMessage, setErrorMessage] = useState(``);

  useEffect(() => {
    setFooter(
      !isInviting && (
        <RoundButton
          onClick={() => setCurrentStep(WelcomeModalStep.ENABLE_APP_EMBED)}
        >
          That&apos;s me! Let&apos;s continue
        </RoundButton>
      ),
    );
  }, [isInviting]);

  const onInvite = async () => {
    if (!validateEmail(inviteeEmail)) {
      setErrorMessage(
        `you must enter a valid email address, e.g: someone@example.com`,
      );
      return;
    }

    if (fullname?.split(` `)?.length < 2) {
      setErrorMessage(`you must enter a valid fullname, e.g: Joe Doe`);
      return;
    }

    setErrorMessage(``);
    await inviteAdminUser(inviteeEmail, fullname);
    if (error) {
      setErrorMessage(`something went wrong, please try again`);
    }
    setCurrentStep(WelcomeModalStep.ENABLE_APP_EMBED);
  };

  return (
    <>
      <WelcomeModalHintText>
        Your account has been automatically created by Shopify using the store
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        owner's account.
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        The owner's email address is: {email}
      </WelcomeModalHintText>
      <VSpace value={1} />
      <Card style={{ position: `relative` }}>
        {loading && (
          <SpinnerWrapper>
            <ExperienceSpinner size={3} />
          </SpinnerWrapper>
        )}
        <TwoColumns>
          {!isInviting ? (
            <>
              <span>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                In case this email isn't yours, please add your email <br />
                as the admin account in Visually.io
              </span>
              <button type="button" onClick={() => setIsInviting(true)}>
                Add my email address
              </button>
            </>
          ) : (
            <span className="bold">
              <CloseWrapper>
                <CloseButton
                  color="#666F7C"
                  onClick={() => setIsInviting(false)}
                />
              </CloseWrapper>
              Add your email address as the activity administrator in
              Visually.io
              <VSpace value={3} />
              <TwoEvenColumns>
                <StyledInput
                  placeholder="Enter your email"
                  onChange={(ev) => setInviteeEmail(ev.target.value)}
                  defaultValue={inviteeEmail}
                />
                <StyledInput
                  placeholder="Enter your fullname"
                  onChange={(ev) => setFullname(ev.target.value)}
                  defaultValue={fullname}
                />
              </TwoEvenColumns>
              {!!errorMessage && (
                <>
                  <VSpace value={1} />
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                  <VSpace value={2} />
                </>
              )}
              {!errorMessage && <VSpace value={3} />}
              <StyledButton onClick={onInvite}>Send</StyledButton>
            </span>
          )}
        </TwoColumns>
      </Card>
    </>
  );
}

export const SpinnerWrapper = styled.div`
  backdrop-filter: blur(8px);
  pointer-events: none;

  display: flex;
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 2rem;
  padding: 2rem;
  align-items: center;

  span {
    text-align: start !important;
    font-size: 1.6rem !important;
    font-weight: normal !important;
    color: #828f9c !important;
    line-height: 1.8;
    font-family: Inter, serif;
  }

  span.bold {
    color: black !important;
    font-weight: bold !important;
    text-align: center !important;
    position: relative;
  }

  button {
    cursor: pointer;
    height: fit-content !important;
    color: #5b656e !important;
    border: 1px solid #5b656e !important;
    border-radius: 3rem !important;
    font-family: Inter, serif !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    letter-spacing: -0.07px !important;
    text-align: center !important;
    padding: 1.5rem 1.8rem !important;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    transition: opacity 0.2s linear;
  }
`;

const ErrorMessage = styled.b`
  color: red;
  font-size: 1.2rem;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: -3.5rem;
  right: -5.5rem;

  && > * {
    border: none !important;
  }

  && > * > * {
    width: 1.5rem;
    height: 1.5rem;
  }

  z-index: 100;
  opacity: 0.37;
`;

const TwoEvenColumns = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled(TextInput)`
  font-size: 1.4rem !important;
  color: black;
  padding: 1.5rem;
  font-weight: 300;
  box-shadow: none;
  border: 1px solid #979797 !important;

  &&::placeholder {
    color: #828f9c;
  }
`;

const StyledButton = styled(RoundButton)`
  && {
    color: white !important;
    border-radius: 1rem !important;
    padding: 1.5rem 15rem !important;
  }
`;
