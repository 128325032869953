import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { Role } from '@/utils/definitions';

export function useInviteUser() {
  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  async function inviteAdminUser(email: string, name: string) {
    const split = name?.split(` `);

    await post(routes.createUser(), {
      email,
      firstName: split?.[0],
      lastName: split?.[1] || ``,
      role: Role.Admin,
    });
  }

  return {
    inviteAdminUser,
    loading,
    error,
  };
}
