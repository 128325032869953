import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { HiEye } from 'react-icons/hi';
import { TbTableExport } from 'react-icons/tb';
import {
  Experience,
  ExperienceStatus,
  UNNAMED_EXPERIENCE,
} from '@/webapi/use-experience-api';
import { AccountContext } from '@/features/account-context';
import { useOptionsModals } from '@/features/dashboard/experiences/use-options-modals';
import { nav, openLinkInNewTab } from '@/utils/browser';
import { editorRoute } from '@/webapi/pages';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { PermanentPreviewModal } from '@/features/dashboard/experiences/preview-modal';
import { routes } from '@/webapi/routes';

export interface ExperienceTileOptionsPopoverProps {
  isVisible: boolean;
  setIsVisible: (b: boolean) => void;
  experience: Experience;
  children: any;
}

export function ExperienceTileOptionsPopover({
  experience,
  children,
  isVisible,
  setIsVisible,
}: ExperienceTileOptionsPopoverProps) {
  const {
    account: {
      store: { isTestStore },
    },
    showAppEmbedModalIfNeeded,
  } = useContext(AccountContext);
  const {
    clone: { CloneModal, cloneModalProps, cloneRef, showCloneModal },
    archive: { ArchiveModal, archiveModalProps, archiveRef, showArchiveModal },
    publish: { PublishModal, publishModalProps, publishRef, showPublishModal },
    pause: { PauseModal, pauseModalProps, pauseRef, showPauseModal },
    delete: { DeleteModal, deleteModalProps, deleteRef, showDeleteModal },
  } = useOptionsModals({ experience });

  const {
    props: previewProps,
    show: showPreviewModal,
    hide: hidePreviewModal,
    fromRef: previewRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () =>
      centered(17.5 + Math.max(experience.variants.length - 2, 0) * 4.35, 42),
  );

  const onEdit = async (ev) => {
    ev.stopPropagation();
    await nav(editorRoute(experience.id));
  };

  const onArchive = async (ev) => {
    ev.stopPropagation();
    showArchiveModal();
    hidePopover();
  };

  const onPublish = async (ev) => {
    ev.stopPropagation();
    if (isTestStore) {
      toast(`Publishing experiences is not available for test stores.`, {
        theme: `colored`,
        type: `info`,
        className: css({ fontFamily: `JetBrains Mono, Serif` }),
      });
    } else if (!showAppEmbedModalIfNeeded()) {
      showPublishModal();
    }
    hidePopover();
  };

  const onPause = async (ev) => {
    ev.stopPropagation();
    showPauseModal();
    hidePopover();
  };

  const onDuplicate = async (ev) => {
    ev.stopPropagation();
    showCloneModal();
    hidePopover();
  };

  const onDelete = async (ev) => {
    ev.stopPropagation();
    showDeleteModal();
    hidePopover();
  };

  const onPreview = (ev) => {
    ev.stopPropagation();
    if (!showAppEmbedModalIfNeeded()) {
      showPreviewModal();
    }
    hidePopover();
  };

  const hidePopover = () => {
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };

  const onExport = () => {
    const url = routes.exportStats(experience.id);
    openLinkInNewTab(url, `Export Data for ${experience.name}`);
    hidePopover();
  };

  return (
    <>
      <CloneModal {...cloneModalProps} />
      <ArchiveModal {...archiveModalProps} />
      <PublishModal {...publishModalProps} />
      <PauseModal {...pauseModalProps} />
      <DeleteModal {...deleteModalProps} />
      {previewProps.isVisible && (
        <PermanentPreviewModal
          overlayProps={previewProps}
          hide={hidePreviewModal}
          experienceId={experience.id}
          themeId={experience.themeId}
          mainThemeId={experience.mainThemeId}
        />
      )}
      <Popover
        containerStyle={{ zIndex: `10` }}
        onClickOutside={() => setIsVisible(false)}
        isOpen={isVisible}
        positions={[`left`, `top`]}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={10}
          >
            <Wrapper>
              <Row onClick={onEdit}>
                <StaticImage
                  src="../../../assets/edit.svg"
                  alt="menu"
                  height={17}
                  placeholder="none"
                  loading="eager"
                />
                {experience.status === ExperienceStatus.ARCHIVED
                  ? `View`
                  : `Edit`}
              </Row>
              {experience.name !== UNNAMED_EXPERIENCE && (
                <>
                  <Separator />
                  <Row onClick={onDuplicate} ref={cloneRef}>
                    <StaticImage
                      src="../../../assets/duplicate.svg"
                      alt="menu"
                      height={30}
                      placeholder="none"
                      loading="eager"
                    />
                    Duplicate
                  </Row>
                  {experience.status === ExperienceStatus.PUBLISHED && (
                    <>
                      <Separator />
                      <Row onClick={onPause} ref={pauseRef}>
                        <StaticImage
                          src="../../../assets/pause.svg"
                          alt="menu"
                          height={30}
                          placeholder="none"
                          loading="eager"
                        />
                        Pause
                      </Row>
                    </>
                  )}
                  {[ExperienceStatus.DRAFT, ExperienceStatus.PAUSED].includes(
                    experience.status,
                  ) && (
                    <>
                      <Separator />
                      <Row onClick={onPublish} ref={publishRef}>
                        <StaticImage
                          src="../../../assets/play.svg"
                          alt="menu"
                          height={15}
                          placeholder="none"
                          loading="eager"
                        />
                        Start
                      </Row>
                    </>
                  )}
                  {![ExperienceStatus.ARCHIVED].includes(experience.status) &&
                    !experience.isPostPurchase && (
                      <>
                        <Separator />
                        <Row onClick={onPreview} ref={previewRef}>
                          <HiEye size="1.5rem" />
                          Preview Link
                        </Row>
                      </>
                    )}
                  {[
                    ExperienceStatus.PAUSED,
                    ExperienceStatus.PUBLISHED,
                    ExperienceStatus.ARCHIVED,
                  ].includes(experience.status) && (
                    <>
                      <Separator />
                      <Row onClick={onExport}>
                        <TbTableExport size={15} />
                        Export Data
                      </Row>
                    </>
                  )}
                  {experience.status !== ExperienceStatus.ARCHIVED &&
                    experience.status !== ExperienceStatus.DRAFT && (
                      <>
                        <Separator />
                        <Row onClick={onArchive} ref={archiveRef}>
                          <StaticImage
                            src="../../../assets/archive.svg"
                            alt="menu"
                            height={30}
                            placeholder="none"
                            loading="eager"
                          />
                          Archive
                        </Row>
                      </>
                    )}
                </>
              )}
              {[ExperienceStatus.DRAFT, ExperienceStatus.ARCHIVED].includes(
                experience.status,
              ) && (
                <>
                  <Separator />
                  <Row onClick={onDelete} ref={deleteRef}>
                    <StaticImage
                      src="../../../assets/archive.svg"
                      alt="menu"
                      height={30}
                      placeholder="none"
                      loading="eager"
                    />
                    Delete
                  </Row>
                </>
              )}
            </Wrapper>
          </ArrowContainer>
        )}
      >
        {children}
      </Popover>
    </>
  );
}

const Wrapper = styled.div`
  width: 18rem;
  display: grid;
  background: white;
  border-radius: 1rem !important;
  box-shadow: 0 12px 18px 2px rgba(0, 0, 0, 0.14),
    0 5px 23px 4px rgba(0, 0, 0, 0.12), 0 6px 8px -4px rgba(0, 0, 0, 0.2) !important;
`;

const Separator = styled.span`
  background: #f1f1f1;
  height: 1px;
  width: 100%;
`;

const Row = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1.2rem 1fr;
  grid-gap: 1.5rem;

  border-radius: 1rem !important;

  padding: 1.5rem 2.3rem;
  align-items: center;
  font-size: 1.2rem;

  img {
    object-fit: contain !important;
  }

  transition: background-color 0.3s linear;

  :hover {
    background: #fafafa;
  }
`;
