import { CachePolicies, useFetch } from 'use-http';
import { useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { Role } from '@/utils/definitions';
import { routes } from '@/webapi/routes';
import { AccountContext } from '@/features/account-context';

interface NewUser {
  email: string;
  firstName: string;
  lastName: string;
}
export function useCreateUserApi(
  hide: () => void,
  refreshUserList: () => void,
) {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const ctx = useContext(AccountContext);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [role, setRole] = useState(Role.Editor);
  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  async function createUser({ email, firstName, lastName }: NewUser) {
    await post(routes.createUser(), {
      email,
      firstName,
      lastName,
      role,
    });
    hide();
    refreshUserList();
  }

  return {
    error,
    loading,
    errors,
    ctx,
    isSelectOpen,
    setIsSelectOpen,
    onCreateUser: handleSubmit(createUser),
    register,
    setRole,
  };
}
