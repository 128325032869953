import * as React from 'react';
import { useContext } from 'react';
import { currencySymbol } from '@/utils/currencies';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import {
  formatNumber,
  Metric,
  MetricVal,
} from '@/features/dashboard/reports_v2/common';
import { maybe } from '@/features/details/utils';
import { NoDataMsg } from '@/features/dashboard/reports_v2/NoData';
import { MetricsGrid } from '@/features/dashboard/reports_v2/top_section/shared';

export function GeneralMetrics() {
  const symbol = currencySymbol();
  const { storeSummary: data } = useContext(ReportingDataContext);
  if (data == null || data?.TotalSessions === 0) {
    return <NoDataMsg />;
  }
  return (
    <MetricsGrid>
      <Metric>
        <p>Total sessions</p>
        <MetricVal mt={-0.5}>{formatNumber(data?.TotalSessions)}</MetricVal>
      </Metric>
      <Metric>
        <p>Total orders</p>
        <MetricVal mt={-0.5}>{formatNumber(data?.TotalOrders)}</MetricVal>
      </Metric>
      <Metric>
        <p>Sold items</p>
        <MetricVal mt={-0.5}>{formatNumber(data?.TotalItemsSold)}</MetricVal>
      </Metric>
      <Metric>
        <p>AOV</p>
        <MetricVal mt={-0.5}>
          {symbol}
          {formatNumber(
            maybe(
              () =>
                Math.round((data!.TotalRevenue / data!.TotalOrders) * 100) /
                100,
            ),
          )}
        </MetricVal>
      </Metric>
      <Metric>
        <p>Revenue</p>
        <MetricVal mt={-0.5}>
          {symbol}
          {formatNumber(data?.TotalRevenue)}
        </MetricVal>
      </Metric>
    </MetricsGrid>
  );
}
